<template>
  <div>
    <b-modal
      id="modal-1"
      ref="valuationCheckMessage"
      title="Disclaimer!"
      ok-only
      ok-title="CONTINUE"
      @ok="closeModal()"
      centered
      >
      <p class="text"
        > Total value of the package is more than INR {{getPriceValidation}} as per the customs guidelines, we cannot ship items more than INR {{getPriceValidation}} in a single shipment. 
        You can consolidate items in multiple packages.</p>
    </b-modal>
  </div>
</template>
    
<script>
import { mapMutations, mapState } from "vuex";
import ConsolidateDialog from './ConsolidateMessage.vue'

export default {
  components: {
    ConsolidateDialog
  },
  computed: mapState({
    getPriceValidation : state => {
      return state.userItems.priceValidation;
    }
  }),
  methods: {
    ...mapMutations({}),
    showModal() {
      this.$refs.valuationCheckMessage.show();
    },
    closeModal() {
      this.$refs.valuationCheckMessage.hide();
    },
    goBack() {
        this.$router.push({name: 'productsInWarehouse'})
    },
    consolidate() {
        let element = this.$refs.consolidateMessage;
        element.showModal();
    }
  },
};
</script>
    
<style scoped>
#modal-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: #414141;
  font-weight: 500;
}
.btn-primary {
  background-color: #4385f5 !important;
}
</style>