<template>
  <div>
    <validation-observer ref="simpleRules">
        <b-form-group id="input-group-firstName" class="form-group">
          <validation-provider
            #default="{ errors }"
            name="FirstName"
            rules="required"
          >
            <b-form-input
              id="name"
              type="text"
              required
              placeholder="Enter First Name"
              :state="errors.length > 0 ? false : null"
              v-model="firstName"
              class="initial"
            />
            <small class="text-danger">{{ errors[0] }}</small> 
          </validation-provider>
        </b-form-group>

        <b-form-group id="input-group-lastName" class="form-group">
          <validation-provider
            #default="{ errors }"
            name="LastName"
            rules="required"
          >
            <b-form-input
              id="name"
              type="text"
              required
              placeholder="Enter Last Name"
              :state="errors.length > 0 ? false : null"
              v-model="lastName"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group id="input-group-contact" class="form-group">
          <validation-provider
            #default="{ errors }"
            name="Contact"
            rules="required"
          >
            <b-form-input
              type="number"
              v-model="phoneNumber"
              required
              :state="errors.length > 0 ? false : null"
              placeholder="Enter Phone Number (Eg: 2212345678)"
              @change="validatePhoneNumber"
              :maxLength="max"
            />
            <small v-if="isValidPhoneNumber == false" class="text-danger">Invalid Phone Number</small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
    </validation-observer>
  </div>
</template>
    
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      required,
      isValidPhoneNumber: true,
      max: "10"
    };
  },
  computed: mapState({
    getFirstNameFromStore: (state) => {
      return state.pickUpStore.firstName;
    },
    getLastNameFromStore: (state) => {
      return state.pickUpStore.lastName;
    },
    getPhoneFromStore: (state) => {
      return state.pickUpStore.contact;
    },
  }),
  methods: {
    ...mapMutations({
      setPickUpPersonsInfo: "pickUpStore/SET_PICKUP_PERSONS_INFO",
    }),
    ...mapActions({}),
    validatePhoneNumber() {
      var regex = /^[6-9]\d{9}$/;
    if (this.phoneNumber.length == 10 && regex.test(this.phoneNumber)) {
        this.isValidPhoneNumber = true;
    }
    else {
        this.isValidPhoneNumber = false;
      } 
   },
    checkValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success && this.isValidPhoneNumber) {
            this.setPickUpPersonsInfo({
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phoneNumber,
          });
          resolve(true)
          } else {
            reject(new Error('Correct all value'))
          }
        })
      })
    },
  },
  watch: {
  }
};
</script>
    
<style>
.text {
  color: #414141;
  font-weight: 500;
}
.btn-primary {
  background-color: #4385f5 !important;
}
.input-group-text {
  color: red;
}
.input-group-text:focus-visible {
  border: none;
  border-radius: 0px;
  outline: none;
}
.input-tel__input {
  border-radius: 0px !important;
}
.input-tel__input:focus-visible {
  border: none;
}
</style>