<template>
  <div class="root">
    <div v-if="itemsToBeReturned.length == 0" class="Empty">
      <EmptyState />
    </div>
    <!-- table -->
    <div v-else class="table-section">
      <div class="blue text_h">Returns And Abandons</div>
      <div v-if="$mq === 'largeDevices'">
      <b-table
        sticky-header="400px"
        :hover="true"
        responsive
        :items="itemsToBeReturned"
        :fields="fields"
      >
      <template v-for="field in itemsToBeReturned"
                 v-slot:cell(pictures)="field">
        <img class="image" v-if="field.item.itemSource == 'store' && field.item.productpictures.length>0" :src="field.item.productpictures[0].url" />
        <img crossorigin="anonymous" class="image" v-else-if="field.item.productpictures.length>0" :src="field.item.productpictures[0].url" />
        <img class="image" v-else alt="No Image Found" />
      </template>

      <template v-for="field in itemsToBeReturned"
                v-slot:cell(returnRequestedDate)="field">
        <div v-if="field.item.displayStatus === 'Returned' || field.item.displayStatus == 'Return Initiated'">{{formatDate(field.item.returnRequestedDate)}}</div>
        <div v-else>{{formatDate(field.item.abandonRequestedDate)}}</div>
      </template>

      <template v-for="field in itemsToBeReturned"
                v-slot:cell(returnCompletedDate)="field">
        <div v-if="field.item.displayStatus === 'Returned' || field.item.displayStatus == 'Return Initiated'">{{formatDate(field.item.returnCompletedDate)}}</div>
        <div v-else>{{formatDate(field.item.abandonCompletedDate)}}</div>
      </template>

      <template v-for="field in itemsToBeReturned"
                v-slot:cell(displayStatus)="field">
        <div v-bind:class="[field.item.displayStatus === 'Returned'? 'blue': field.item.displayStatus === 'Abandoned'? 'blue': '']">{{field.item.displayStatus}}</div>
      </template>
      </b-table>
      </div>
      <div v-else>
        <div class="blue heading-return">Returns and Abandons</div>
      <div v-for="item in itemsToBeReturned" class="cont__">
        <div class="card return_content">
          <div class="left_cont">
            <div class="imge_">
              <img class="image" v-if="item.itemSource == 'store' && item.productpictures.length>0" :src="item.productpictures[0].url" />
              <img crossorigin="anonymous" class="image" v-else-if="item.productpictures.length>0" :src="item.productpictures[0].url" />
              <img class="image" v-else alt="No Image Found" />
            </div>
            <div class="blue text_ name">
              {{item.itemName}}
            </div>
          </div>
          <div class="middle_cont">
            <div v-if="field.item.displayStatus === 'Returned' || field.item.displayStatus == 'Return Initiated'" class="text_">Req: <span class="blue">{{formatDate(item.returnRequestedDate)}}</span></div>
            <div v-else class="text_">Req: <span class="blue">{{formatDate(item.abandonRequestedDate)}}</span></div>
            <div v-if="field.item.displayStatus === 'Returned' || field.item.displayStatus == 'Return Initiated'" class="text_">Comp: <span class="blue">{{formatDate(item.returnCompletedDate)}}</span></div>
            <div v-else class="text_">Comp: <span class="blue">{{formatDate(item.abandonCompletedDate)}}</span></div>
          </div>
          <div class="right_cont">
            <div class="text_" v-bind:class="[item.displayStatus == 'Returned'?'blue':'']">{{item.displayStatus}}</div>
            <div class="text_">{{item.reason}}</div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapState, mapMutations } from "vuex";
import { BNav, BNavItem, BNavbarNav } from "bootstrap-vue";
import {
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
} from "bootstrap-vue";

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormCheckboxGroup,

} from "bootstrap-vue";
import EmptyState from './EmptyStateReturnAndAbandon.vue';
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BTable,
    BAvatar,
    BBadge,
    BCardBody,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BNav,
    BNavItem,
    BNavbarNav,
    BPagination,
    BFormCheckbox,
    BFormCheckboxGroup,
    EmptyState
  },
  data() {
    return {
      activeNavItemId: 0,
      suiteItems: [],
      fields: [
        { label: "", key: "pictures" },
        { label: "Item Name", key: "itemName" },
        { label: "Status", key: "displayStatus" },
        { label: "Requested Date", key: "returnRequestedDate" },
        { label: "Completed On", key: "returnCompletedDate" },
        { label: "Reason", key: "reason" },
      ],
      perPage: 25,
      currentPage: 1,
      allSelected: false,
      val: "",
      selectedItems: [],
      totalValue: 0,
    };
  },
  directives: {
    Ripple,
  },
  computed: mapState({
    itemsToBeReturned: state => {
      return state.userItems.returnedItemList
    }
  }),
  mounted() {
    this.getReturnItems({currentPage: 1, pageSize: this.perPage})
    this.setSidebarRoute('return-abandon')
    this.setMyOrdersNavbar('')
    this.setNavbarRoute('dashboardLanding')
  },

  methods: {
    ...mapActions({
      getReturnItems: 'userItems/showItemsToBeReturned'
    }),
    ...mapMutations({
      setSidebarRoute: 'routesStore/SET_SIDEBAR',
      setMyOrdersNavbar: 'routesStore/ SET_MY_ORDERS_NAVBAR',
      setNavbarRoute: 'routesStore/SET_NAVBAR'
    }),
    formatDate(date) {
      if(!date){
        return ""
      }
      let datee = new Date(date);
      let monthNameShort = datee.toLocaleString('default', { month: 'short' });
      const [year,month, day] = date.split("-");
      return [day, monthNameShort, year].join("-");
    },
  },
};
</script>

<style scoped>
.active-text {
  color: #4385f5;
}
.table-section{
  margin-top: 1rem;
}
.Empty {
  margin-top: 4rem;
}
.root {
  padding: 2rem 6rem;
  background-color: #fff;
}
.bttn2 {
  background: #4385f5;
  color: #ffffff;
  min-width: 160px;
}
.buttons {
  margin-top: 3rem;
}
.image {
  width: 55px;
  height: 53px;
}
.qty {
  text-align: center;
}
.itemInfo {
  border-collapse: collapse;
  width: 100%;
}
.itemInfo td,
.itemInfo th {
  border: 1px solid #ddd;
  padding: 8px;
}
.itemInfo th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}
.input-group-text {
  color: #000000;
  font-weight: bold;
  border-radius: 0px;
}
.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1vw;
}
.demo-inline-spacing {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}
.page-item.active .page-link {
  background-color: #4385f5;
}
.btn {
  border-radius: 0%;
}
.blue {
  color: #4385F5;
}
.red {
  color: #F06A6A;
}
.text_h {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1200px){
  .table-section {
    margin-top: 1rem;
  }
  .root {
    padding: 0rem;
  }
  .cont__ {
    width: 100%;
    padding: 0rem 1rem 0rem 1rem;
  }
  .return_content {
    padding: 0.7rem;
    width: 100%;
    min-height: 82px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 0.5rem;
  }
  .left_cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25vw;
  }
  .middle_cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40vw;
  }
  .right_cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35vw;
  }
  .imge_ {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image {
    width: 60px;
    height: 58px;
  }
  .heading-return {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 1rem;
  }
  .name {
    display: flex;
    justify-content: center;
  }
  .text_ {
    font-size: 1rem;
  }
}

</style>