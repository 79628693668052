<template>
  <div>
    <!-- <Navbar /> -->
    <div class="root">
      <div class="sidebar"></div>
      <div class="newOuterContainer">
        <div class="item1 mainBox">
          <div class="t">
            <div class="tb-img">
              <img :src="userProfileIcon" />
            </div>
            <div class="tb-title">My Profile & Password</div>
            <div class="circle" @click="clickHandler">
              <i class="fa-solid fa-pen"></i>
            </div>
          </div>
          <div class="tb-desc">
            <div class="">
              First Name: <b>{{ userFName }}</b>
            </div>
            <div class="">
              Last Name: <b>{{ userLName }}</b>
            </div>
            <div class="">
              Email: <b>{{ userEmail }}</b>
            </div>
            <div class="">
              Contact Number: <b>{{ userContact }}</b>
            </div>
            <div class="">
              Suite Number: <b>{{ SuitNo }}</b>
            </div>
          </div>
          <!-- <div v-bind:class="[editButtonClicked ? '' : 'bd1']"></div>
          <div class="bdb1" v-bind:class="[editButtonClicked ? '' : 'xyz']">
            <b-button variant="primary" @click="clickHandler">Edit</b-button>
          </div> -->
        </div>
        <div class="item2 mainBox">
          <div class="t">
            <div class="tb-img">
              <img :src="senderDetailsIcon" />
            </div>
            <div class="tb-title">Indian Sender Detail</div>
            <div class="circle" @click="editSender">
              <i class="fa-solid fa-pen"></i>
            </div>
          </div>
          <div class="tb-desc">
            <div class="isdr">
              <div class="isdc1">Aadhar card(front & back)</div>
              <div class="isdc2" v-if="getAF && getAB">✅</div>
            </div>
            <div class="isdr">
              <div class="isdc1">Contact No</div>
              <div class="isdc2" v-if="getContact">✅</div>
            </div>
          </div>
          <!-- <div v-bind:class="[editButtonClicked ? '' : 'tc']"></div>
          <div class="tc2" v-bind:class="[editButtonClicked ? '' : 'xyz']">
            <b-button variant="primary" @click="editSender">Edit</b-button>
          </div> -->
        </div>
        <div class="item3 mainBox">
          <div class="t">
            <div class="tb-img">
              <img :src="destinationIcon" />
            </div>
            <div class="tb-title">Your Delivery Destinations</div>
            <div class="circle" @click="editDeliveryDest">
              <i class="fa-solid fa-pen"></i>
            </div>
          </div>
          <div class="tb-desc">
            <div>Address Line 1 : {{ al1 }}</div>
            <div>Address Line 2 : {{ al2 }}</div>
            <div>Pincode : {{ zipCode }}</div>
            <div>City : {{ city }}</div>
            <div>Phone : {{ phone }}</div>
          </div>
          <!-- <div v-bind:class="[editButtonClicked ? '' : 'bd3']"></div>
          <div class="bdb3"  v-bind:class="[editButtonClicked ? '' : 'xyz']">
            <b-button variant="primary" @click="editDeliveryDest">Edit</b-button>
          </div> -->
        </div>
        <div class="item4 mainBox">
          <div class="t">
            <div class="tb-img">
              <img :src="addressIcon" />
            </div>

            <div class="tb-title">My Virtual Address</div>
          </div>
          <div class="tb-desc">
            <label for="fName" class="l">Full Name</label>
            <div class="copyLink">
              <input
                type="text"
                class="copyLinkInput"
                name=""
                id="fName"
                :value="userFName + ' ' + userLName"
              />
              <button type="button" class="copyLinkBtn" @click="copy1">
                <img src="./copy.svg" alt="" />
              </button>
            </div>
            <br />
            <br />
            <br />
            <label for="addresses" class="l">Street Address</label>
            <div class="copyLink">
              <textarea
                class="copyLinkInput2"
                :value="warehouseComponents[0]"
                readonly
              />
              <button type="button" class="copyLinkBtn" @click="copy2">
                <img src="./copy.svg" alt="" />
              </button>
            </div>
            <br />
            <div class="copyLink">
              <textarea
                class="copyLinkInput2"
                :value="warehouseComponents[1]"
                readonly
              />
              <button type="button" class="copyLinkBtn" @click="copy3">
                <img src="./copy.svg" alt="" />
              </button>
            </div>
            <br />
            <br />
            <br />
            <label for="cty" class="l">City</label>
            <div class="copyLink">
              <input
                type="text"
                class="copyLinkInput"
                name=""
                id="cty"
                :value="warehouseComponents[2]"
              />
              <button type="button" class="copyLinkBtn" @click="copy4">
                <img src="./copy.svg" alt="" />
              </button>
            </div>
            <br />
            <label for="zipcode" class="l">Pincode</label>
            <div class="copyLink">
              <input
                type="text"
                class="copyLinkInput"
                name=""
                id="zipcode"
                :value="warehouseComponents[3]"
              />
              <button type="button" class="copyLinkBtn" @click="copy5">
                <img src="./copy.svg" alt="" />
              </button>
            </div>
            <br />
            <label for="phone_num" class="l">Phone Number</label>
            <div class="copyLink">
              <input
                type="text"
                class="copyLinkInput"
                name=""
                id="phone_num"
                :value="senderPhoneNumber"
              />
              <button type="button" class="copyLinkBtn" @click="copy6">
                <img src="./copy.svg" alt="" />
              </button>
            </div>
            <small class="text-secondary">(Used for delivery purpose).</small>
          </div>
        </div>
      </div>

      <UserDetails ref="profileDetails"></UserDetails>
      <IndianSenderDetailsM2 ref="senderModal"></IndianSenderDetailsM2>
      <deliveryDestModal ref="deliveryModal" flag="true"></deliveryDestModal>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";

import profile from "../../assets/yicimages/dashboard/profile.png";
import sender from "../../assets/yicimages/dashboard/sender.png";
import compass from "../../assets/yicimages/dashboard/compass.png";
import home from "../../assets/yicimages/dashboard/home.png";
import UserDetails from "./UserDetails.vue";
import IndianSenderDetailsM2 from "./IndianSenderDetailsM2.vue";
import Navbar from "../navbar/Navbar.vue";
import deliveryDestModal from "./SelectAddress.vue";

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    UserDetails,
    Navbar,
    IndianSenderDetailsM2,
    deliveryDestModal,
  },
  //   props: ["list"],
  data() {
    return {
      userProfileIcon: profile,
      destinationIcon: compass,
      addressIcon: home,
      senderDetailsIcon: sender,
      description:
        "Lorem Lorem ipsum dolor sit amet, consectetur ad incididunt ut.000000 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      al1: '',
      al2: '',
      zipCode: '',
      city: '',
      phone: '',
      val2: '',
      senderContact: '',
      editButtonClicked: false
    };
  },
  directives: {
    Ripple,
  },

  computed: {
    ...mapState({
      getFName: (state) => {
        return state.senderDetails.firstName;
      },
      getLName: (state) => {
        return state.senderDetails.lastName;
      },
      getAddrL1: (state) => {
        return state.senderDetails.addrL1;
      },
      getAddrL2: (state) => {
        return state.senderDetails.addrL2;
      },
      getAF: (state) => {
        return state.senderDetails.aadharFront;
      },
      getAB: (state) => {
        return state.senderDetails.aadharBack;
      },
      getContact: (state) => {
        return state.senderDetails.contact;
      },
      senderDetailsExist: (state) => {
        return state.senderDetails.addressExists;
      },
      WarehouseAddress: (state) => {
        return state.warehouseAddress.virtualAddress;
      },
      SuitNo: (state) => {
        return state.warehouseAddress.suitNumber;
      },
      warehouseComponents: (state) => {
        return state.warehouseAddress.warehouseComponents;
      },
      st: (state) => {
        return state.deliveryDestination.stt;
      },
      country: (state) => {
        return state.deliveryDestination.country;
      },
      fullName: (state) => {
        return state.deliveryDestination.fName;
      },
      phone: (state) => {
        return state.deliveryDestination.phone;
      },
      userFName: (state) => {
        return state.editedProfile.firstName;
      },
      userLName: (state) => {
        return state.editedProfile.lastName;
      },
      userEmail: (state) => {
        return state.editedProfile.email;
      },
      userContact: (state) => {
        return state.editedProfile.contactNumber;
      },
      userId: (state) => {
        return state.basicprofilemodule.uid
      },
      defaultAddress: state => {
        return state.address.addresses
      },
      senderPhoneNumber: state=> {
        return state.warehouseAddress.phoneNumber
      }
    }),
  },
  methods: {
    copy1() {
      navigator.clipboard.writeText(this.userFName+" "+this.userLName);
    },
    copy2() {
      navigator.clipboard.writeText(this.warehouseComponents[0]);
    },
    copy3() {
      navigator.clipboard.writeText(this.warehouseComponents[1]);
    },
    copy4() {
      navigator.clipboard.writeText(this.warehouseComponents[2]);
    },
    copy5() {
      navigator.clipboard.writeText(this.warehouseComponents[3]);
    },
    copy6() {
      navigator.clipboard.writeText(this.senderPhoneNumber);
    },
    clickHandler() {
      this.editButtonClicked = true;
      let element = this.$refs.profileDetails;
      element.showModal();
    },
    editSender() {
      this.editButtonClicked = true;
      let element = this.$refs.senderModal;
      element.dummy();
      console.log("CALLLEDDD");
    },
    copyHandler() {
      navigator.clipboard.writeText(this.WarehouseAddress);
    },
    editDeliveryDest() {
      let element = this.$refs.deliveryModal;
      element.showModal();
    },
    ...mapActions({
      getWarehouseAddress: "warehouseAddress/getWarehouseAddress",
      getSenderDetails: "senderDetails/getSenderDetails",
      getUserDetails: "editedProfile/getUserDetails",
      getDeliveryDestinations: "deliveryDestination/getAllAddresses",
    }),
    ...mapMutations({
      setSidebarRoute: "routesStore/SET_SIDEBAR",
      setNavbarRoute: "routesStore/SET_NAVBAR",
    }),
    async showDefaultAddress() {
      const res = await this.defaultAddress.filter(add => add.isDefault == true && add.showAddress == true);
      if(res.length>0){
        this.al1 = res[0].addressLine1;
        this.al2 = res[0].addressLine2;
        this.zipCode = res[0].zipCode;
        this.city = res[0].city;
        this.phone = res[0].phone;
      }
      else {
        this.al1 = '';
        this.al2 = '';
        this.zipCode = '';
        this.city = '';
        this.phone = '';
      }
    }
  },
  async mounted() {
    if (this.$route.query.openModal) {
      this.editSender();
    }
    await this.getDeliveryDestinations();
    this.getWarehouseAddress(this.userId);
    this.getSenderDetails();
    this.getUserDetails();
    this.setSidebarRoute("account-settings");
    this.setNavbarRoute("dashboardLanding");
    await this.showDefaultAddress();
    this.$root.$on('closeModal', () => {
            // your code goes here
            this.editButtonClicked = false
    })
  },
  watch: {
    defaultAddress(oVal, nVal){
      if(nVal!=oVal){
         this.showDefaultAddress();
      }
    },
    getAF(oVal, nVal){

    },
    getAB(oVal, nVal){
      
    },
    getContact(oVal, nVal){

    }
  }
};
</script>

<style scoped>
.root {
  padding: 1rem 2rem;
  background-color: #fff;
  margin-top: 27px;
  margin-left: 14%;
}

.t {
  margin-bottom: 20px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.l {
  margin: 0%;
  padding: 0%;
  color: #000000;
}
.circle {
  background-color: white;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4385f5;
  cursor: pointer;
}

.copyLink {
  display: flex;
  width: 100%;
}
.copyLinkInput {
  width: 85%;
  height: 35px;
  border: 1px solid #808080;
  border-right: none;
  outline: none;
  background-color: #f8f8f8;
}
.copyLinkInput2 {
  width: 85%;
  height: auto;
  border: 1px solid #808080;
  border-right: none;
  outline: none;
  background-color: #f8f8f8;
  resize: none;
}

.copyLinkBtn {
  width: 11%;
  border: 1px solid #808080;
  background-color: #f8f8f8;
}
.newOuterContainer {
  /* background-color: #4385f5; */
  display: grid;
  gap: 25px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 50% 50%;
  /* width: 100%;
  height: 100%; */
}

.mainBox {
  background-color: #f8f8f8;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 0.6rem;
}
.boxContent {
  /* width: fit-content; */
  /* display: inline-block; */
}
.test {
  /* display: inline-block; */
}

/* .item1:hover {
  background-color: red;
} */

.tc {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.65;
  /* visibility: hidden; */
  display: none;
  background-color: black;
  z-index: 2000;
}

.bd1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.65;
  /* visibility: hidden; */
  display: none;
  background-color: black;
  z-index: 2000;
}
.bd3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.65;
  /* visibility: hidden; */
  display: none;
  background-color: black;
  z-index: 2000;
}

.bdb1 {
  position: absolute;
  left: 165px;
  top: 100px;
  display: none;
}
.xyz {
  z-index: 3000;
}
.bdb3 {
  position: absolute;
  left: 165px;
  top: 105px;
  display: none;
}
.tc2 {
  position: absolute;
  left: 165px;
  top: 80px;
  display: none;
}

.item1:hover .bd1 {
  display: block;
}
.item2:hover .tc {
  display: block;
}
.item3:hover .bd3 {
  display: block;
}
.item1:hover .bdb1 {
  display: block;
}
.item2:hover .tc2 {
  display: block;
}
.item3:hover .bdb3 {
  display: block;
}
.item1 {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 2;
}

/* .item1:after {
    content:'\A';
    position:absolute;
    width:100%; height:100%;
    top:0; left:0;
    background:rgba(0,0,0,0.6);
    opacity:0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
} */

/* .item1:hover:after {
    opacity:1;
} */

.item2 {
  position: relative;
  grid-row-start: 2;
  grid-row-end: 3;
}
.item3 {
  position: relative;
  grid-row-start: 3;
  grid-row-end: 4;
}
.item4 {
  /* position: relative; */
  grid-row-start: 1;
  grid-row-end: 4;
}
.text-area {
  height: 30%;
  margin: auto auto;
  display: grid;
  grid-template-columns: 0.8fr 0.8fr;
  gap: 1.5rem;
  /* width: 85%; */
  /* height: 50%; */
  grid-template-rows: 0.9fr 0.9fr;
}
.bttn {
  align-self: flex-end;
  /* left: 0px; */
  background: #4385f5;
  color: #ffffff;
  height: 40px;
  width: 320px;
  /* margin-top: 1.2rem; */
}
.isdr {
  position: relative;
}

.isdc1 {
  display: inline-block;
}

.isdc2 {
  display: inline-block;
  position: absolute;
  right: 0;
}
.tb-title {
  display: inline;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 0.8rem;
  /* white-space: nowrap; */
}
.tb-img {
  padding: 17px;
  width: 3rem;
  height: 3rem;
  background: #ffffff;
  /* display: flex; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 0.6rem;
  margin-right: 20px;
  /* margin-top: 50px; */
}
.tb-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #555555;
  margin-bottom: 10px;
}
.boldd {
  font-weight: 500;
  color: #000000;
}
@media screen  and (max-width: 1200px){
  .text-area {
    display: flex;
    flex-direction: column;
  }
  .text-box {
    width: 26rem;
    margin-left: -90px;
  }
  .newOuterContainer {
    display: flex;
    flex-direction: column;
  }
  .root{
    padding: 1rem;
    margin: 0;
  }
  .t {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .tb-img {
    margin-right: 10px;
    padding: 10px;
  }
  .tb-title {
    font-size: 1.2rem;
  }
  .mainBox {
    padding: 1rem;
  }
  .tb-desc {
    font-size: 1rem;
  }
}
</style>
