<template>
  <div class="rootLandingPage">
    <div class="dashboard-landing-text">Dashboard</div>
    <b-card class="card">
      <div class="card-text-1">
        <b-card-text class="count">{{
          valueFormat(getPackagesReadyForPayment(),2)
        }}</b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="open-button"
          @click="goToConsolidationStatus()"
          >Open <img class="arrow" :src="arrow"
        /></b-button>
      </div>
      <div class="card-text-2">
        <b-card-text v-if="getPackagesReadyForPayment() == 1" class="desc"
          >Package Ready for Payment</b-card-text
        >
        <b-card-text v-else class="desc">Package Ready for Payment</b-card-text>
      </div>
    </b-card>

    <b-card class="card">
      <div class="card-text-1">
        <b-card-text class="count">{{
          valueFormat(numberOfItemsInWarehouse.length,2)
        }}</b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="open-button"
          @click="goToProductInWarehouse()"
          >Open <img class="arrow" :src="arrow"
        /></b-button>
      </div>
      <div class="card-text-2">
        <b-card-text class="desc" v-if="numberOfItemsInWarehouse.length == 1"
          >Item in Warehouse
        </b-card-text>
        <b-card-text class="desc" v-else>Items in Warehouse </b-card-text>
      </div>
    </b-card>

    <b-card class="card">
      <div class="card-text-1">
        <b-card-text class="count">{{
          valueFormat(getNumberOfPackagesInConsolidationStatus(),2)
        }}</b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="open-button"
          @click="goToConsolidationStatus()"
          >Open <img class="arrow" :src="arrow"
        /></b-button>
      </div>
      <div class="card-text-2">
        <b-card-text class="desc" v-if="getNumberOfPackagesInConsolidationStatus() == 1">Package in Warehouse </b-card-text>
        <b-card-text class="desc" v-else>Packages in Warehouse </b-card-text>
      </div>
    </b-card>

    <b-card class="card">
      <div class="card-text-1">
        <b-card-text class="count">{{valueFormat(getNumberOfPackagesInPackageTracking(),2)}}</b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="open-button"
          @click="goToPackageTracking()"
          >Open <img class="arrow" :src="arrow"
        /></b-button>
      </div>
      <div class="card-text-2">
        <b-card-text class="desc" v-if="getNumberOfPackagesInPackageTracking() == 1">Package in Transit </b-card-text>
        <b-card-text class="desc" v-else>Packages in Transit </b-card-text>
      </div>
    </b-card>

    <b-card class="card">
      <div class="card-text-1">
        <b-card-text class="count">{{
          valueFormat(getNumberOfPackagesDelivered(),2)
        }}</b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="open-button"
          @click="goToPackagesDelivered()"
          >Open <img class="arrow" :src="arrow"
        /></b-button>
      </div>
      <div class="card-text-2">
        <b-card-text class="desc" v-if="getNumberOfPackagesDelivered() == 1">Package Delivered </b-card-text>
        <b-card-text class="desc" v-else>Packages Delivered </b-card-text>
      </div>
    </b-card>

    <b-card class="card">
      <div class="card-text-1">
        <b-card-text class="count">{{
          valueFormat(numberOfPickUpPackages.length,2)
        }}</b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="open-button"
          @click="goToPickUpPage()"
          >Open <img class="arrow" :src="arrow"
        /></b-button>
      </div>
      <div class="card-text-2">
        <b-card-text class="desc">Pickup Status </b-card-text>
      </div>
    </b-card>

    <b-card class="card">
      <div class="card-text-1">
        <b-card-text class="count">{{
          valueFormat(getNumberOfPackagesInReadyForShipping(),2)
        }}</b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="open-button"
          @click="goToViewPaymentCompletedItems()"
          >Open <img class="arrow" :src="arrow"
        /></b-button>
      </div>
      <div class="card-text-2">
        <b-card-text class="desc" v-if="getNumberOfPackagesInReadyForShipping() == 1">Payment Completed</b-card-text>
        <b-card-text class="desc" v-else>Payments Completed</b-card-text>
      </div>
    </b-card>
    <router-view />
  </div>
</template>

<script>
import { BCard, BCardText, BButton } from "bootstrap-vue";
import Arrow from "./Arrow.svg";
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from "vue-ripple-directive";
import MyOrders from "../dashboard/MyOrders/MyOrders.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    MyOrders,
  },
  data() {
    return {
      arrow: Arrow,
      productInWarehouse: false,
    };
  },
  directives: {
    Ripple,
  },
  computed: mapState({
    numberOfItemsInWarehouse: (state) => {
      return state.userItems.allItems;
    },
    numberOfPackagesInWarehouse: (state) => {
      return state.shippingPackages.itemsToBeShipped;
    },
    numberOfPickUpPackages: (state) => {
      return state.pickUpStore.pickupPackagesDetails;
    },
  }),
  methods: {
    ...mapActions({
      findItems: "userItems/showUserItems",
      getPackages: "shippingPackages/getShippedItems",
      getPickUpPackages: "pickUpStore/getPickUpPackages",
    }),
    ...mapMutations({
      setSidebarRoute: "routesStore/SET_SIDEBAR",
      setNavbarRoute: "routesStore/SET_NAVBAR",
    }),
    goToProductInWarehouse() {
      this.$router.push({ name: "productsInWarehouse" });
    },
    goToConsolidationStatus() {
      this.$router.push({ name: "consolidationStatus" });
    },
    goToReadyForShipping() {
      this.$router.push({ name: "readyForShipping" });
    },
    goToPackageTracking() {
      this.$router.push({ name: "packageTracking" });
    },
    goToPackagesDelivered() {
      this.$router.push({ name: 'packagesDelivered'})
    },
    getPackagesReadyForPayment() {
      return Object.values(this.numberOfPackagesInWarehouse).filter(
        (pkg) => pkg.paymentStatus === "Ready For Payment"
      ).length;
    },
    getNumberOfPackagesInReadyForShipping() {
      return Object.values(this.numberOfPackagesInWarehouse).filter(
        (pkg) => pkg.paymentStatus === "Done"
      ).length;
    },
    getNumberOfPackagesInConsolidationStatus() {
        return Object.values(this.numberOfPackagesInWarehouse).filter(
        (pkg) => pkg.paymentStatus != "Done"
      ).length;
    },
    getNumberOfPackagesInPackageTracking() {
        return Object.values(this.numberOfPackagesInWarehouse).filter(
        (pkg) => pkg.shippingStatus == 'Completed'
      ).length;
    }, 
    getNumberOfPackagesDelivered() {
        return Object.values(this.numberOfPackagesInWarehouse).filter(
        (pkg) => pkg.shippingStatus == 'Delivered'
      ).length;
    },
    goToPickUpPage() {
      this.$router.push({ name: "pickup" });
    },
    goToViewPaymentCompletedItems() {
      this.$router.push({name: 'paymentsCompleted'});
    },
    valueFormat(num, size) {
      var s = num + "";
      while (s.length < size) {
        s = "0" + s;
      }
      return s;
    },
  },
  mounted() {
    this.findItems({ currentPage: 1, pageSize: 25, index: 0 });
    this.getPackages();
    this.setNavbarRoute("dashboardLanding");
    this.setSidebarRoute("");
    this.getPickUpPackages();
  },
};
</script>

<style scoped>
.rootLandingPage {
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #ffffff;
}
.card {
  background: #f8f8f8;
  width: 30rem;
  height: 12rem;
  margin: 2rem;
}
.card > * {
  margin: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-text-1 {
  display: flex;
  justify-content: space-between;
}
.count {
  margin-top: 3%;
  color: black;
  font-size: 6rem;
  font-weight: 300px;
}
.desc {
  font-size: 1.3em;
  color: #4385f5;
}
.open-button {
  width: 5rem;
  height: 2rem;
  font-size: 1rem;
  padding: 1px;
  color: #808080;
  border: 1px solid #808080 !important;
  border-radius: 0px;
}
.dashboard-landing-text {
  display: none;
 }
/* mobile screen */
@media screen and (max-width: 1200px) {
  .rootLandingPage {
    margin: 0rem;
    padding: 1rem;
    width: 100%;
    height: 100%;
  }
  .card {
  background: #f8f8f8;
  width: 100%;
  height: 8em;
  margin: 0.5rem;
 }
 .card > * {
  padding: 0.5em;
 }
 .count {
  font-size: 3em;
 }
 .dashboard-landing-text {
  display: block;
  font-size: 2rem;
  color:#4385f5;
  margin: 0rem 0rem 0.5rem 0.5rem;
  font-weight: 500;
 }
 .desc {
  font-size: 1.3rem;
 }
}
@media screen and (min-width: 1250px) and (max-width: 1400px) {
 .card {
  width: 25em;
  height: 10em;
 }  
 .card> *{
  padding: 1rem;
 }
 .count {
  font-size: 4rem;
 }
}
</style>
