<template>
  <div class="item-modal">
    <b-modal id="modal-center" centered title="List of items you added in your cart" size="lg" ok-only ref="itemsModal">
      <b-table sticky-header="400px" :bordered="true" :hover="true" responsive :items="val"
        :fields="fields" v-if="$mq === 'largeDevices'">

        <template v-for="field in val" v-slot:cell(pictures)="field">
          <div class="picture">
            <img class="image" :src="field.item.product[0].productImages[0]"/>
          </div>
        </template>

        <template v-for="field in val" v-slot:cell(name)="field">
          {{ field.item.product[0].name }}
        </template>

        <template v-for="field in val" v-slot:cell(price)="field">
          <span style="display: flex;"><span style="font-weight: 500;">&#8377;</span>{{ field.item.variant[0].priceOffer ? field.item.variant[0].priceOffer * field.item.quantity : field.item.variant[0].priceOriginal * field.item.quantity }}</span>
        </template>

        <template v-for="field in val" v-slot:cell(value)="field">
          <div class="prepend-value">
            <div class="prepend">&#8377;</div>
            <input class="value" v-model="field.item.value" required @change="changeValue(field.item)" type="number" />
          </div>
        </template>

      </b-table>
      <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
        <div class="card item-cards" v-for="item in val">
          <div class="s_card_">
            <div style="width: 20rem; display: flex; align-items: center;">
              <div class="picture">
                  <img class="image" :src="item.product[0].productImages[0]" alt="no img found"/>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; justify-content: space-between; width: 70rem;">
              <div class="item_name blue">{{ shortName(item.product[0].name) }}</div>
              <div>₹ {{item.variant[0].priceOffer ? item.variant[0].priceOffer * item.quantity : item.variant[0].priceOriginal * item.quantity}}</div>
            </div>
            <div style="display: flex; flex-direction: column; justify-content: space-between; width: 10rem;">
              <div class="item_qty">{{ item.quantity }}</div>
              <div class="item_qty">
                <div class="prepend-value">
                  <div class="prepend">&#8377;</div>
                  <input class="value" v-model="item.value" required @change="changeValue(item)" type="number" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BAlert, BCardText, BTable } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  props: ["val", "shippingPackageId"],
  components: {
    BTable,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  data() {
    return {
      fields: [
        { label: "", key: "pictures" },
        { label: "Item Name", key: "name" },
        { label: "Item Price", key: "price" },
        "quantity",
        { label: "Value", key: "value" },
      ],
      totalItems: 0,
    };
  },
  directives: {
    Ripple,
  },
  computed: mapState({
    // selectedPackageItems(state) {
    //   this.totalItems = state.shippingPackages.itemsToBeShipped.filter(
    //     (selectedPackage) => selectedPackage.id === parseInt(this.id)
    //   )[0].selectedItems.length
    //   return state.shippingPackages.itemsToBeShipped.filter(
    //     (selectedPackage) => selectedPackage.id === parseInt(this.id)
    //   )[0].selectedItems;
    // },
  }),
  mounted() { },
  methods: {
    ...mapActions({
      updateValue: "storeShoppingCartItems/updateValue",
      getItemsInShippingPackage: "storeShoppingCartItems/getItemsInShippingPackage"
    }),
    itemSelected(item) {
      const itemId = item.id;
      this.$router.push({ name: "view-item", params: { id: itemId } });
    },
    showModal() {
      this.$refs.itemsModal.show()
    },
    async changeValue(item) {
      await this.updateValue({ id: item.id, value: item.value })
      await this.getItemsInShippingPackage({shippingPackageId: this.shippingPackageId, getQuantity: false})
    },
    shortName(name){
      let str = name;
      if (str.length > 10) return str.slice(0, 10) + '...'
      return name
    }
  },
};
</script>
<style scoped>
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.picture {
  width: 5rem;
  height: 5rem;
}

.prepend-value {
  display: flex;
}

.prepend {
  background: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 33px;
  color: #000000;
  font-weight: 600;
}

.value {
  border: 0.5px solid #C9C9C9;
  width: 80px;
  height: 33px;
}

.value:focus-visible {
  outline: 0.5px solid #C9C9C9;
}

@media screen and (max-width: 1200px) {
  .s_card_ {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: auto;
    padding: 0.3rem;
    margin: 0rem;
    align-items: center;
  }

  .picture {
    width: 5vw;
    height: 5vh;
    display: flex;
  }

  .image_1 {
    width: 5vw;
    height: 5vh;
    object-fit: contain;
  }

  .item-cards {
    margin: 0.3rem;
  }

  #modal-center {
    padding: 0rem;
    margin-right: 1rem;
  }

  .blue {
    color: #4385f5;
  }

  .item_qty {
    font-weight: 500;
  }
  .prepend {
    background: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 25px;
    color: #000000;
    font-weight: 600;
  }
  .value {
    border: 0.5px solid #C9C9C9;
    width: 60px;
    height: 25px;
  }
}</style>