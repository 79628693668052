<template>
    <div>
        <b-modal
        ref="volumetricWeightModal"
        title="What is Volumetric Weight?"
        ok-only
        centered
        >
        <div class="content_">
            For the considered weight we will calculate both the volumetric weight as
            well as the actual weight of the shipment and choose whichever is
            greater.
          </div>
          <div class="img_container">
            <div class="box_image"></div>
          </div>
          <div class="lst">
            <span class="vw"> Volumetric Weight (kgs) = </span>
            <div class="lst-parts">
              <div class="lst-1">L x W x H</div>
              <div class="lst2">5000</div>
            </div>
          </div>
        </b-modal>
    </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
export default {
    components:{
        BModal
    },
    methods: {
        showModal() {
            this.$refs.volumetricWeightModal.show();
        }
    }
}
</script>

<style scoped>
.content_ {
    font-size: 13px;
    line-height: 20px;
}
.box_image {
    width: 290.31px;
    height: 209.79px;
    background-image: url("../../../assets/yicimages/dashboard/box.png");
    margin-bottom: 3rem;
    margin-top: 2rem;
}
.lst {
    display: flex;
    gap: 0.5rem;
    text-align: center;
    width: 400px;
    height: 50px;
}
.lst-1 {
    border-bottom: 1px solid #4b2d2d;
}
.vw {
    font-weight: 500;
    line-height: 20px;
}
.img_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>