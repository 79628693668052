<template>
  <div>
    <Navbar value="3" />
    <div class="root" v-if="$mq === 'largeDevices'">
      <div class="image-side">
        <div class="selected-image-container">
          <img v-if="selectedItem.itemSource == 'store'" class="selected-image" :src="selectedItem.productpictures[index].url" />
          <img v-else class="selected-image" :src="selectedItem.productpictures[index].url" crossorigin="anonymous" />
        </div>
        <div v-if="selectedItem.itemSource == 'store'" class="images">
          <div v-for="(img, i) in selectedItem.productpictures" :key="img.id" :id="img.id" class="images-img"
            @click="imageClickedHandler(i)" :class="{ active: parseInt(selectedImageId) === i }">
            <!-- {{selectedImageId}}{{i}} -->
            <img :src="img.url" class="small-img" />
            <!-- <img v-else :src="notFound" class="small-img" /> -->
          </div>
        </div>
        <div v-else class="images">
          <div v-for="(img, i) in selectedItem.productpictures" :key="img.id" :id="img.id" class="images-img"
            @click="imageClickedHandler(i)" :class="{ active: parseInt(selectedImageId) === i }">
            <!-- {{selectedImageId}}{{i}} -->
            <img :src="img.url" class="small-img" crossorigin="anonymous" />
            <!-- <img v-else :src="notFound" class="small-img" /> -->
          </div>
        </div>
      </div>
      <div class="text-side">
        <!-- <h2 class="id-number">{{ selectedItem.itemID }}</h2> -->
        <h1 class="title">{{ selectedItem.itemName }}</h1>
        <div class="item-box">
          <p class="qty">Qty: {{ selectedItem.quantity }}</p>
          <p class="date">Date received: {{ formatDate(selectedItem.whenArrived) }}</p>
          <!-- date format -->
        </div>
        <h3 class="value">&#8377;{{ selectedItem.value }}</h3>
        <div class="list-container">
          <div :key="selectedItem.description" class="list-item">
            <span class="list-item-heading">Item Description : </span>
            <span>{{ selectedItem.description }}</span>
          </div>
          <div :key="selectedItem.category" class="list-item">
            <span class="list-item-heading">Item category : </span>
            <span>{{ selectedItem.itemCategory }}</span>
          </div>
          <div :key="selectedItem.merchant" class="list-item">
            <span class="list-item-heading">Merchant : </span>
            <span>{{ selectedItem.itemSource }}</span>
          </div>
          <div :key="selectedItem.fulfilmentStatus" class="list-item">
            <span class="list-item-heading">Fulfillment status : </span>
            <span v-if="selectedItem.fulfilmentStatus == 'Fulfilled'" class="dispFulFilled">Fulfilled</span>
            <span v-else class="dispPending">Pending</span>
          </div>
        </div>
        <div class="buttons" v-if="!selectedItem.shippingpackageId">
          <b-button class="bttn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="abandonHandler"
            v-if="selectedItem.itemSource != 'store'">
            <u class="txtUl">
              <div style="margin-bottom: 3px; display: inline-block">
                Abandon Item
              </div>
            </u>
            <span class="tooltip1">
              <i class="fa-solid fa-circle-info hover-info"></i>
              <span class="tooltiptext">
                <b-card>
                  <b-card-text class="card-text">
                    <i class="fa-solid fa-circle-info"></i>
                    Important!
                    <div class="card-content">
                      Return can only be initiated if you have placed return on the merchants end.
                    </div>
                  </b-card-text>
                </b-card>
              </span>
            </span>
          </b-button>

          <b-button class="bttn3" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="consolidateHandler" v-if="selectedItem.itemSource != 'store' && selectedItem.itemSource != 'home'">
            Return Item
            <span class="tooltip1">
              <i class="fa-solid fa-circle-info hover-info"></i>
              <span class="tooltiptext">
                <b-card>
                  <b-card-text class="card-text">
                    <i class="fa-solid fa-circle-info"></i>
                    Important!
                    <div class="card-content">
                      Return can only be initiated if you have placed return on the merchants end.
                    </div>
                  </b-card-text>
                </b-card>
              </span>
            </span>
          </b-button>
        </div>
      </div>
      <div>
        <b-button style="" variant="primary" @click="goBack()" class="go_back">Go Back</b-button>
      </div>
    </div>
    <div class="root" v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
      <div class="title">{{ selectedItem.itemName }}</div>
      <div class="item-box">
        <div class="qty">Qty: <div class="blue">{{ selectedItem.quantity }}</div>
        </div>
        <div class="date">Date received: <div class="blue">{{ formatDate(selectedItem.whenArrived) }}</div>
        </div>
      </div>
      <div class="image-top">
        <div class="selected-image-container">
          <img v-if="selectedItem.itemSource == 'store'" class="selected-image" :src="selectedItem.productpictures[index].url" />
          <img v-else class="selected-image" crossorigin="anonymous" :src="selectedItem.productpictures[index].url" />
        </div>
        <div class="images">
          <div v-for="(img, i) in selectedItem.productpictures" :key="img.id" :id="img.id" class="images-img"
            @click="imageClickedHandler(i)" :class="{ active: parseInt(selectedImageId) === i }">
            <!-- {{selectedImageId}}{{i}} -->
            <img v-if="selectedItem.itemSource == 'store'" :src="img.url" class="small-img" />
            <img v-else :src="img.url" class="small-img" crossorigin="anonymous" />
            <!-- <img v-else :src="notFound" class="small-img" /> -->
          </div>
        </div>
      </div>
      <div class="text">
        <!-- <h2 class="id-number">{{ selectedItem.itemID }}</h2> -->

        <h3 class="value">&#8377;{{ selectedItem.value }}</h3>
        <div class="list-container">
          <div :key="selectedItem.description" class="list-item">
            <span class="list-item-heading">Description : </span>
            <span>{{ selectedItem.description }}</span>
          </div>
          <div :key="selectedItem.category" class="list-item">
            <span class="list-item-heading">Category : </span>
            <span>{{ selectedItem.itemCategory }}</span>
          </div>
          <div :key="selectedItem.merchant" class="list-item">
            <span class="list-item-heading">Merchant : </span>
            <span>{{ selectedItem.itemSource }}</span>
          </div>
          <div :key="selectedItem.fulfilmentStatus" class="list-item">
            <span class="list-item-heading">Status : </span>
            <span v-if="selectedItem.fulfilmentStatus == 'Fulfilled'" class="dispFulFilled">Fulfilled</span>
            <span v-else class="dispPending">Pending</span>
          </div>
        </div>
        <div class="buttons" v-if="!selectedItem.shippingpackageId">
          <b-button class="bttn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="abandonHandler"
            v-if="selectedItem.itemSource != 'store'">
            Abandon Item
            <span class="tooltip1">
              <i class="fa-solid fa-circle-info hover-info"></i>
              <span class="tooltiptext">
                <b-card>
                  <b-card-text class="card-text">
                    <i class="fa-solid fa-circle-info"></i>
                    Important!
                    <div class="card-content">
                      Return can only be initiated if you have placed return on the merchants end.
                    </div>
                  </b-card-text>
                </b-card>
              </span>
            </span>
          </b-button>

          <b-button class="bttn3" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="consolidateHandler" v-if="selectedItem.itemSource != 'store'">
            Return Item
            <span class="tooltip1">
              <i class="fa-solid fa-circle-info hover-info"></i>
              <span class="tooltiptext">
                <b-card>
                  <b-card-text class="card-text">
                    <i class="fa-solid fa-circle-info"></i>
                    Important!
                    <div class="card-content">
                      Return can only be initiated if you have placed return on the merchants end.
                    </div>
                  </b-card-text>
                </b-card>
              </span>
            </span>
          </b-button>
        </div>
      </div>
    </div>
    <ReturnMessage ref="returnMessage" :value="selectedItem"></ReturnMessage>
    <abandonM1 ref="abandonM1" :det="{ itemID: this.$route.params.id }"></abandonM1>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import Navbar from "../navbar/Navbar.vue";
import abandonM1 from "./abandonM1.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";

import img1 from "../../assets/yicimages/dashboard/img1.jpg";
import img2 from "../../assets/yicimages/dashboard/img2.png";
import img3 from "../../assets/yicimages/dashboard/img3.png";
// import imgNotAvailable from "../assets/yicimages/dashboard/imgNotAvailable.jpg";
import ReturnMessage from './ReturnMessage.vue'
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity,
  }
})

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Navbar,
    ReturnMessage,
    abandonM1,
  },
  props: ["value", "det"],
  data() {
    return {
      notFound: img1,
      index: 0,
      selectedImageId: 0,
    };
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState({
      selectedItem: (state) => {
        return state.userItems.selectedItem;
      },
    }),
    id() {
      // return this.values.id;
      const SI = this.selectedItem;
      return SI.id;
    },
    name() {
      return this.values.name;
    },
    qty() {
      return this.values.qty;
    },
    value() {
      return this.values.value;
    },
    description() {
      return this.values.description;
    },
    dateRecieved() {
      return this.values.dateRecieved;
    },
    category() {
      return this.values.category;
    },
    merchant() {
      return this.values.merchant;
    },
    isFulfilled() {
      return this.values.isFulfilled;
    },
    // images() {
    //   return this.values.images;
    // },

    ListItems() {
      return [
        { id: 1, name: "Item Description", value: "selectedItem.description" },
        { id: 2, name: "Category", value: "selectedItem.itemCategory" },
        { id: 3, name: "Merchant", value: "selectedItem.itemSource" },
        // { id: 4, name: "Fulfillment Status", value: selectedItem.isFulfilled },
      ];
    },
  },
  methods: {
    goBack() {
      // this.$router.push({name: 'productsInWarehouse'})
      this.$router.go(-1)
    },
    abandonHandler() {
      this.$refs.abandonM1.dummy();
    },
    goBackHandler() {
      this.$router.go(-1);
    },
    consolidateHandler() {
      this.$refs.returnMessage.showModal()
    },
    imageClickedHandler(i) {
      console.log(i);
      // this.selectedImageId = e.currentTarget.id;
      this.selectedImageId = i;
      this.index = i;
    },
    ...mapMutations({
      setSidebarRoute: 'routesStore/SET_SIDEBAR',
      setMyOrdersNavbar: 'routesStore/SET_MY_ORDERS_NAVBAR',
      setNavbarRoute: 'routesStore/SET_NAVBAR'
    }),
    ...mapActions({
      loadSelectedItem: "userItems/loadSelectedItem",
    }),
    formatDate(date) {
      let datee = new Date(date);
      let monthNameShort = datee.toLocaleString('default', { month: 'short' });
      const [year,month, day] = date.split("-");
      return [day, monthNameShort, year].join("-");
    },

  },
  mounted() {
    const itemId = this.$route.params.id;
    console.log("Item Selected : ", itemId);
    this.loadSelectedItem(itemId);
    this.setSidebarRoute('')
    this.setMyOrdersNavbar('')
    this.setNavbarRoute('dashboardLanding')
  },
};
</script>
<style scoped>
.root {
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  padding: 2rem 6rem;
  background-color: white;
}

.txtUl {
  padding: 5px;
}

.dispFulFilled {
  color: #10a531;
}

.dispPending {
  color: #e51e1e;
}

.images {
  display: flex;
  justify-content: space-evenly;
  width: 94%;
}

.images-img {
  max-width: 150px;
  max-height: 140px;
  overflow: hidden;
  padding: 0.3rem;
}

.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #414141;
}

.qty {
  color: #4385f5;
}

.value {
  color: #414141;
  font-weight: 500;
  font-size: 30px;
  line-height: 33px;
}

.item-box {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.3rem;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 1.3rem;
}

.list-container {
  border-bottom: 1px solid #bcbcbc;
  border-top: 1px solid #bcbcbc;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 0;
  margin-top: 2.2rem;
  width: 80%;
}

.list-item {
  font-size: 18px;
  line-height: 33px;
}

.list-item-heading {
  color: #414141;
  font-weight: 500;
}

.bttn1 {
  background: #4385f5;
  color: #fff;
  min-width: 160px;
}

.bttn2 {
  background: #4385f5;
  color: #ffffff;
  min-width: 160px;
}

.bttn3 {
  background: #d9d9d9;
  color: #4385f5;
  min-width: 160px;
}

.buttons {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  gap: 1.4rem;
}

.id-number {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
}

.text-side {
  padding-left: 1rem;
}

.selected-image-container {
  width: 475px;
  height: 400px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.active {
  border: 2px solid #4385f5;
}

.small-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.success {
  color: #10a531;
  font-weight: 500;
}

.pending {
  color: #f06a6a;
  font-weight: 500;
}

.image-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hover-info:hover {
  transform: scale(1.2);
}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 15rem;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -70px;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}

.card-content {
  margin-top: 0.5rem;
  font-size: 12px;
}

.card-text {
  background-color: #9d9898;
  color: #ffffff;
  border-radius: 6px;
}
.go_back {
  margin-top: 1rem;
  background-color: #4385f5 !important;
}

/* mobile screens */
@media screen and (max-width: 1200px) {
  .root {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    padding-top: 0;
    justify-content: center;
  }

  .images {
    display: flex;
    justify-content: space-evenly;
    width: 94%;
  }

  .images-img {
    max-width: 100px;
    max-height: 100px;
    overflow: hidden;
  }

  .selected-image {
    height: 100%;
    width: 100%;
  }

  .selected-image-container {
    width: 60vw;
    height: auto;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10vw;
  }

  .qty {
    display: flex;
    color: black;
    margin: 0;
    font-size: 1.2rem;
  }

  .date {
    display: flex;
    /* justify-content: space-between; */
    color: black;
    font-size: 1.2rem;
  }

  .item-box {
    display: flex;
    flex-direction: column;
    margin: 0rem 0rem 1rem 0rem;
    gap: 0;
  }

  .blue {
    color: #4385f5;
    margin-left: 1rem;
  }

  .image-top {
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
  }

  .bttn1 {
    width: 100%;
    height: fit-content;
  }

  .bttn3 {
    width: 100%;
    height: fit-content;
  }

  .title {
    font-size: 8vw;
    padding-top: 0;
  }

  .list-item-heading {
    font-size: 1.2rem;
  }
}
</style>