import { render, staticRenderFns } from "./PackageTracking.vue?vue&type=template&id=6ef6d595&scoped=true&"
import script from "./PackageTracking.vue?vue&type=script&lang=js&"
export * from "./PackageTracking.vue?vue&type=script&lang=js&"
import style0 from "./PackageTracking.vue?vue&type=style&index=0&id=6ef6d595&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef6d595",
  null
  
)

export default component.exports