<template>
  <div class="container">
    <!-- modal -->
    <b-modal ref="abandonM1" id="modal-center" centered hide-footer hide-header>
      <div class="abandonText">
        <p class="mainTitle">Continue with Abandonment</p>
        <p class="titleContent">
          Once the items are abandoned it would get discarded/dumped
        </p>
        <br />
        <p class="reason"><strong>Select the reason for abandonment</strong></p>
        <input id="prohibited" v-on:change="handleCheckbox" type="checkbox" style="display: inline" />
        <p class="prohibited" style="display: inline-block">Prohibited items</p>
        <textarea
          class="textArea"
          name=""
          rows="4"
          cols="55"
          style="display: block; border: 1px solid #c9c9c9"
          placeholder="  Enter your reason"
          v-model="reason"
        >
        </textarea>
        <button class="cancel" @click="cancel">
          <div class="cancelText">Cancel</div>
        </button>
        <button class="confirm" id="qwerty" @click="ok">
          <div class="confirmText">Confirm</div>
        </button>
      </div>
    </b-modal>
    <abandonM2 ref="abandonM2" :det2="{reason: this.reason, itemID: this.det.itemID, pc: this.isChecked}"></abandonM2>
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import abandonM2 from "./abandonM2.vue";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
export default {
  props: ["det", "det2"],
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    abandonM2,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  data() {
    return {
      // modalShow: this.open,
      modalShow: false,
      list: [1, 2, 3, 4],
      reason: "",
      isChecked: false
    };
  },

  computed: {
    ...mapState({
      addressList: (state) => state.deliveryDestination.addresses,
    }),
  },

  methods: {
    dummy() {
      this.$refs["abandonM1"].show();
    },
    ok() {
      this.$refs["abandonM1"].hide();
      this.$refs["abandonM2"].dummy();
    },
    cancel() {
      this.$refs["abandonM1"].hide();
    },
    handleCheckbox() {
      this.isChecked = !this.isChecked;
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
input[type="checkbox"] {
  /* border: none !important; */
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.root {
  display: inline-block;
  min-height: 470px;
  max-width: 200px !important;
  width: 200px;
  padding: 2rem;
}

.textArea {
  display: block;
  border: 1px solid #c9c9c9;
}

.mainTitle {
  font-family: "poppins" !important;
  font-weight: 600;
  font-size: 20px;
  color: #225aa5;
}

.titleContent {
  font-family: "poppins" !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;
  color: #414141;
}

.reason {
  font-family: "poppins";
  font-weight: 600;
  font-size: 18px;
  color: #414141;
}

/* .container {
    position: relative;
} */
.cancel {
  background-color: #d9e7fd;
  color: #4385f5 !important;
  border-radius: 0% !important;
  border: none;
  margin-top: 5%;
  margin-right: 3%;
  width: 20%;
  height: 35px;
}

.cancelText {
  font-family: "poppins";
  font-weight: 500;
  font-size: 16px;
}

.confirmText {
  font-family: "poppins";
  font-weight: 500;
  font-size: 16px;
}

.confirm {
  background-color: #4385f5 !important;
  color: #ffffff !important;
  border-radius: 0% !important;
  border: none;
  margin-top: 5%;
  width: 20%;
  height: 35px;
}

#id {
  background-color: #4385f5 !important;
  color: #ffffff !important;
}

.btn-secondary {
  background-color: #d9e7fd !important;
}
</style>
