<template>
  <div class="root_main">
    <div v-if="$mq === 'largeDevices'">
      <div class="root1">
        <div class="root-box" v-b-toggle="`${id__}`">
          <div class="id blue special-border">Package {{ value.packageNo }}</div>
          <div class="date special-border">
            Requested on:
            <span class="blue">{{
              formatDate(value.date.substring(0, 10))
            }}</span>
          </div>
          <div class="number special-border">
            No. of Items: <span class="blue">{{ value.numberOfItems }}</span>
          </div>
          <div class="status">
            <span :class="[!status ? 'orange' : 'green']">{{ status ? "Ready For Payment" : "Consolidation In-Progress"
            }}
              <span class="tooltip1">
                <i class="fa fa-circle-info hover-info"></i>
                <span class="tooltiptext">
                  <b-card :class="[!status ? 'card-text-orange' : 'card-text-green']">
                    <b-card-text>
                      <div :class="[!status ? 'orange' : 'green']">
                        <i class="fa fa-circle-info"></i>
                        {{
                          status ? "Ready For Payment" : "Consolidation In-Progress"
                        }}
                      </div>
                      <div class="card-content">
                        For the final weight we will calculate both the volumetric weight as well as the actual weight of
                        the package and whichever is greater will be used.
                      </div>
                    </b-card-text>
                  </b-card>
                </span>
              </span>
            </span>
          </div>

          <div class="details blue" @click="showItemsInPackage">
            View Items
            <span v-if="show == false" class="blue"><i class="fa-solid fa-circle-chevron-down"></i></span>
            <span v-else class="blue"><i class="fa-solid fa-circle-chevron-up"></i></span>
          </div>
        </div>
      </div>

      <div class="container" v-if="status">
        <b-collapse :id="id__" class="upper" v-if="show">
          <div>
            <ConsolidatedItems :id="id__" />
          </div>
        </b-collapse>
        <div class="rroot" :class="[show == false ? '' : 'backdrop']">
          <div class="left_">
            <div class="packageinfo_">
              <div class="h1">Package Info</div>
              <div class="total_items">
                <div v-if="yourCartItems.has(value.id)" class="f_size">Total Items: <span style="font-weight: bold;">{{
                  value.selectedItems.length + yourCartItems.get(value.id).length }}</span>
                </div>
                <div v-else class="f_size">Total Items: <span style="font-weight: bold;">{{ value.selectedItems.length
                }}</span></div>
              </div>

              <div class="total_items">
                <div class="f_size" style="display: flex;">Considered weight for shipping:
                  <div>
                    <span  :class="isMaxChanged ? 'blinker' : 'orange'" style="margin-left: 0.5rem;">{{
                      consideredWeight
                    }} KG</span>
                  </div>
                </div>
              </div>
              <div class="f_size">Existing package weight: <span class="orange">{{ packageItemsWeight }} KG</span></div>
              <div style="display: flex;" class="f_size">New package weight:
                <span :class="isYourCartItemsChanged ? 'blinker' : 'orange'" style="margin-left: 0.5rem;"> {{
                  allItemsWeight }} KG</span>
              </div>
              <div class="total_items">
                <div class="f_size">Volumetric Weight: <span class="orange" style="font-weight: bold;">{{
                  value.volumetricWeight }} KG</span></div>
                <i class="fa-solid fa-circle-info icons__" @click="showVolumetricWeightModal"></i>
              </div>
              <div class="f_size">Package Dimensions (cms): <span style="font-weight: bold;">{{ value.packageDimensions
              }}</span></div>
              <div class="total_items">
                <div style="display: flex;" class="f_size">Total value considered for shipping:
                  <span style="margin-left: 0.5rem; font-weight: 500;" class="orange">₹ {{
                    totalItemsPrice }}</span>
                </div>
              </div>
            </div> 
            <div class="info_box" v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0">
              <i class="fa-solid fa-circle-info" />
              We've added a 200g buffer weight to your new package weight, covering any small variations.
            </div>
            <div class="deliveryAddress">
              <div class="h1">Delivery Address</div>
                <textarea readonly class="d_address" id="autoResizeTextarea">
                  {{ value.selectedAddress[0].addressLine1 }},
                  {{ value.selectedAddress[0].addressLine2 }},
                  {{ value.selectedAddress[0].city }},
                  {{ value.selectedAddress[0].state }},
                  {{ value.selectedAddress[0].country }},
                  {{ value.selectedAddress[0].zipCode }}
                </textarea>
            </div>
            <div class="your_cart">
              <div class="h1">Your Cart</div>
              <div v-if="!yourCartItems.has(value.id)" class="no_items_card">
                <img src="./NoItemsInCartImg.svg" />
                <div>“ <span class="no_items_text">Your cart is currently empty. </span>Add the recommended YIC products
                  to keep exploring.”</div>
              </div>
              <div v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0" class="y_cart">

                <div v-for="item in yourCartItems.get(value.id)" class="your_cart_items" v-if="item.quantity>0">
                  <div class="img_container">
                    <img :src="item.product[0].productImages[0]" class="your_cart_items_image" />
                  </div>
                  <div style="width: 60%;">
                    <div class="your_cart_items_text">{{ shortName(item.product[0].name) }}</div>
                    <div class="your_cart_items_text">{{ item.variant[0].shippingWeight }}gms</div>
                    <div class="your_cart_items_text" style="display: flex;">Qty:
                       <!-- {{ item.quantity }} -->
                       <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; align-items: center; margin-left: 0.5rem;" class="qty_rect">
                        <div class="qty_handler" @click="handleItemsInShippingPackage('decrease', item)"><span>-</span></div>
                        <div class="qty_handler" style="background: none;">{{item.quantity}}</div>
                        <div v-if="showAddBtn(item)" class="qty_handler" @click="handleItemsInShippingPackage('increase', item)"><span>+</span></div>
                        <div v-else class="qty_handler_2"><span>+</span></div>
                       </div>
                    </div>
                  </div>
                  <div style="font-weight: bold;">
                    ₹ {{ calcPrice(item) }}
                  </div>
                  <i class="fa-solid fa-trash icons__" @click="removeItems(item)" style="color: #f54141"></i>
                </div>
              </div>
            </div>
            <div class="shippingDetails">
              <div class="h1">Select a Shipping Partner</div>
              <div v-if="shippingPartnersDetails.has(value.id)">
                <div class="shippingPartner" v-for="item in shippingPartnersDetails.get(value.id)">
                  <div>
                    <input name="shippingPartnerName" v-on:click="shippingPartner([item])"
                      type="radio" />
                    <label>{{ item.shippingPartnerName }}</label>
                  </div>
                  <div class="vl"></div>
                  <div style="color: #4385f5">₹ {{ item.price }}</div>
                </div>

              </div>
            </div>

            <div class="buttons" :class="[show == false ? '' : 'backdrop']">
              <b-button class="bttn2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="clickHandler"
                :disabled="isButtonDisabled()">
                Continue to Payment
              </b-button>
            </div>
          </div>
          <!-- right side -->
          <div class="right_" v-if="totalItemsPrice <= maxShippingPackagePrice">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <h4 v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0" class="orange">Recommended
                for Consolidation</h4>
              <h4 v-else class="orange">"Enhance Your Order with Recommendations"</h4>
              <i v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0"
                class="fa-solid fa-circle-info icons__" id="popover-target-1" />
            </div>
            <b-popover target="popover-target-1" triggers="hover" placement="top" style="width: auto;">
              <div style="font-size: 1.5rem; font-weight: bold; margin-bottom: 1rem; color: #150035;">Color Indicators on
                the Progress Bar</div>
              <div>At YIC, we simplify your shopping experience with color indicators on the progress bar:</div>
              <b-progress :max="1000" style="width:100%; margin-top: 1rem; margin-bottom: 1rem; height: 0.7rem;">
                <b-progress-bar value="200" style="background-color: #e43535;"></b-progress-bar>
                <b-progress-bar value="500" style="background-color: #2ead23;"></b-progress-bar>
                <b-progress-bar value="300" style="background-color: #0b6802;"></b-progress-bar>
              </b-progress>
              <div style="display: flex;">
                <div class="rectangle_dimensions">
                  <div class="rectangle_1"></div>
                </div>
                <div>Indicates a 200g buffer weight added by YIC to accommodate minor variations.</div>
              </div>
              <div style="display: flex;">
                <div class="rectangle_dimensions">
                  <div class="rectangle_2"></div>
                </div>
                <div>Represents consolidation items in your cart.</div>
              </div>
              <div style="display: flex;">
                <div class="rectangle_dimensions">
                  <div class="rectangle_3"></div>
                </div>
                <div>Signals fulfillment based on the considered weight.</div>
              </div>
            </b-popover>
            <div v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0">You're just {{ diff * 1000
            }}
              gms away from filling a {{ consideredWeight }} kg consolidated package and saving on shipping costs.</div>
            <div v-else>Discover items carefully curated to complement your selection. Explore and elevate your shopping
              experience. Thank you for choosing YIC!</div>
            <div v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0" class="progress_bar_text">
              <b-progress :max="max" :precision="2" show-value class="progress_bar" style="width:100%;">
                <b-progress-bar :value="buffer" style="width:10%; background-color: #e43535;"></b-progress-bar>
                <b-progress-bar :value="packageItemsWeight"
                  style="width:50%; background-color: #2ead23;"></b-progress-bar>
                <b-progress-bar :value="cartItemsWeightInKg" :style="dynamicStyle()"
                  style="background-color: #0b6802;"></b-progress-bar>
              </b-progress>
              <span style="font-weight: bold; display: flex;">({{ totalWeight / 1000 }}/{{ max }})KG</span>
            </div>
            <CategoryButtons v-if="!yourCartItems.has(value.id)" :shippingPackageId="value.id"
              :remainingWeight="diff * 1000" />
            <CategoryButtons v-else :shippingPackageId="value.id"
              :remainingWeight="((Math.ceil(allItemsWeight) - allItemsWeight).toFixed(2)) * 1000 - 200" />

            <Items v-if="!yourCartItems.has(value.id)" :shippingPackageId="value.id" :remainingWeight="diff * 1000" />
            <Items v-else :shippingPackageId="value.id"
              :remainingWeight="((Math.ceil(allItemsWeight) - allItemsWeight).toFixed(2)) * 1000 - 200" />
          </div>
          <div class="right_2" v-else-if="totalItemsPrice > maxShippingPackagePrice && !isItemsReviewed">
            <div class="package_limit_exceeded">
              <div style="color: #FF6B6B; font-size: 1.5rem; font-weight: bold;">Oops! Shipping Limit Reached</div>
              <div><img src="./ShippingLimitReachedImg.svg" /></div>
              <div style="padding: 1rem;">
                <div>
                  You've reached the maximum allowed courier shipment amount as per our guidelines. To continue shipping
                  items, please consider consolidating your package.
                </div>
              </div>
              <div class="package_limit_exceeded_btns">
                <b-button class="border_button rev_btn" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="reviewItemsCalled">
                  Review Items
                </b-button>
              </div>
            </div>
          </div>
          <div class="right_3" v-if="totalItemsPrice > maxShippingPackagePrice && isItemsReviewed">
            <div>
              <div style="color: #FF6B6B; font-size: 1.8rem; font-weight: bold;">Review package valuation</div>
              <div style="font-size: 1rem;">Shipping value per package is maximum of <span style="font-weight: 500;">₹{{maxShippingPackagePrice}}</span>. Please review.</div>
              <br />
              <div style="font-size: 1rem;">Shipping value is the value which one can claim if the package is lost in transit and also this value will be submitted for customs documentation.</div>
            </div>
            <div class="size__">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div class="text_3">Edit Existing Items</div>
                <div @click="viewAllItems" style="cursor: pointer;"><i class="fa-regular fa-pen-to-square"></i></div>
              </div>
              <div class="display_row">
                <div>Total Items</div>
                <div>{{ value.selectedItems.length }}</div>
              </div>
              <div class="display_row">
                <div>Existing Items Value</div>
                <div>₹ {{ existingItemsPrice }}</div>
              </div>
            </div>
            <div class="size__">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div class="text_3">Edit Cart Items</div>
                <div @click="viewCartItems" style="cursor: pointer;"><i class="fa-regular fa-pen-to-square"></i></div>
              </div>
              <div class="display_row">
                <div>Total Items</div>
                <div>{{ yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0 ?
                  yourCartItems.get(value.id).length : 0 }}</div>
              </div>
              <div class="display_row">
                <div>Cart Items Value</div>
                <div>₹ {{ cartItemsValue }}</div>
              </div>
              <hr class="custom_hr">
              <div class="display_row">
                <div>Total</div>
                <div style="color: #FF6B6B; font-weight: bold;">₹ {{ totalItemsPrice }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-collapse :id="id__" v-if="!status && show">
        <div>
          <ConsolidatedItems :id="id__" />
        </div>
      </b-collapse>

    </div>
    <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
      <div class="card">
        <div class="left">
          <div class="packageNo">{{ value.packageNo }}</div>
          <div class="blue"> {{ value.numberOfItems }}<span v-if="value.numberOfItems <= 1"> item </span>
            <span v-else> items </span>
          </div>
          <div class="date">
            {{ formatDate(value.date.substring(0, 10)) }}
          </div>
        </div>
        <div class="right">
          <span :class="[!status ? 'orange' : 'green']">{{ status ? "Ready For Payment" : "In-Progress" }}
            <span class="tooltip1">
              <i class="fa fa-circle-info hover-info"></i>
              <span class="tooltiptext">
                <b-card :class="[!status ? 'card-text-orange' : 'card-text-green']">
                  <b-card-text>
                    <div :class="[!status ? 'orange' : 'green']">
                      <i class="fa fa-circle-info"></i>
                      {{
                        status ? "Ready For Payment" : "Consolidation In-Progress"
                      }}
                    </div>
                    <div class="card-content">
                      For the final weight we will calculate both the volumetric weight as well as the actual weight of
                      the package and whichever is greater will be used.
                    </div>
                  </b-card-text>
                </b-card>
              </span>
            </span>
          </span>
          <div class="view-item blue" @click="showItemsInPackage">View Items
            <span v-if="show == false" class="blue"> <i class="fa-solid fa-circle-chevron-down"></i></span>
            <span v-else class="blue"> <i class="fa-solid fa-circle-chevron-up"></i></span>
          </div>
        </div>
      </div>
      <div class="shippingDetails container" v-if="status">
        <b-collapse :id="id__" v-if="show" class="upper" style="display: block">
          <div>
            <ConsolidatedItems :id="id__" />
          </div>
        </b-collapse>
        <div class="shipping-details-content" :class="[show == false ? '' : 'backdrop']">
          <div class="left_">
            <div class="packageinfo_">
              <div class="h1">Package Info</div>
              <div class="total_items">
                <div v-if="yourCartItems.has(value.id)" class="f_size">Total Items: <span style="font-weight: bold;">{{
                  value.selectedItems.length +
                  yourCartItems.get(value.id).length }}</span></div>
                <div v-else class="f_size">Total Items: <span style="font-weight: bold;">{{ value.selectedItems.length
                }}</span></div>
              </div>

              <div class="f_size">Considered weight for shipping:
                <span :class="isMaxChanged ? 'blinker' : 'orange'" style="margin-left: 0.5rem;">{{
                  consideredWeight
                }} KG</span>
              </div>
              <div class="f_size">Existing package weight: <span class="orange">{{ packageItemsWeight }} KG</span></div>
              <div class="f_size">New package weight:
                <span :class="isYourCartItemsChanged ? 'blinker' : 'orange'" style="margin-left: 0.5rem;"> {{
                  allItemsWeight }} KG</span>
              </div>
              <div class="f_size">Package Dimensions (cms): <span style="font-weight: bold;">{{ value.packageDimensions
              }}</span></div>
              <div class="total_items">
                <div class="f_size">Volumetric Weight: <span style="font-weight: bold;">{{ value.volumetricWeight }} KG
                  </span></div>
                <i class="fa-solid fa-circle-info icons__" @click="showVolumetricWeightModal"></i>
              </div>
              <div class="total_items">
                <div style="display: flex;" class="f_size">Total value considered for shipping:
                  <span style="margin-left: 0.5rem; font-weight: 500;" class="orange">₹ {{
                    totalItemsPrice }}</span>
                </div>
              </div>
            </div>
            <div class="info_box" v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0">
              <i class="fa-solid fa-circle-info" />
              We've added a 200g buffer weight to your new package weight, covering any small variations.
            </div>
            <div class="deliveryAddress">
              <div class="h1">Delivery Address</div>
              <textarea readonly class="d_address">
                {{ value.selectedAddress[0].addressLine1 }},
                {{ value.selectedAddress[0].addressLine2 }},
                {{ value.selectedAddress[0].city }},
                {{ value.selectedAddress[0].state }},
                {{ value.selectedAddress[0].country }},
                {{ value.selectedAddress[0].zipCode }}
              </textarea>
            </div>
            <div class="your_cart">
              <div class="h1">Your Cart</div>
              <div v-if="!yourCartItems.has(value.id)" class="no_items_card">
                <img src="./NoItemsInCartImg.svg" />
                <div>“ <span class="no_items_text">Your cart is currently empty. </span>Add the recommended YIC products
                  to keep exploring.”</div>
              </div>
              <div v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0" class="y_cart">
                <div v-for="item in yourCartItems.get(value.id)" class="your_cart_items">
                  <div class="img_container">
                    <img :src="item.product[0].productImages[0]" class="your_cart_items_image" />
                  </div>
                  <div style="width: 45%; margin-left: 0.5rem">
                    <div class="your_cart_items_text">{{ shortName2(item.product[0].name) }}</div>
                    <div class="your_cart_items_text">{{ item.variant[0].shippingWeight }}gms</div>
                    <div class="your_cart_items_text" style="display: flex;">Qty:
                      <!-- {{ item.quantity }} -->
                      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; align-items: center; margin-left: 0.5rem;" class="qty_rect">
                       <div class="qty_handler" @click="handleItemsInShippingPackage('decrease', item)"><span>-</span></div>
                       <div class="qty_handler" style="background: none;">{{item.quantity}}</div>
                       <div v-if="showAddBtn()" class="qty_handler" @click="handleItemsInShippingPackage('increase', item)"><span>+</span></div>
                       <div v-else class="qty_handler_2"><span>+</span></div>
                      </div>
                   </div>
                  </div>
                  <div style="font-weight: bold;">
                    ₹ {{ calcPrice(item) }}
                  </div>
                  <i class="fa-solid fa-trash icons__" @click="removeItems(item)" style="color: #f54141"></i>
                </div>
              </div>
            </div>
            <div class="shippingDetails">
              <div class="h1">Select a Shipping Partner</div>
              <div v-if="shippingPartnersDetails.has(value.id)">
                <div class="shippingPartner" v-for="item in shippingPartnersDetails.get(value.id)">
                  <div>
                    <input name="shippingPartnerName" v-on:click="shippingPartner([item])"
                      type="radio" />
                    <label>{{ item.shippingPartnerName }}</label>
                  </div>
                  <div class="vl"></div>
                  <div style="color: #4385f5">₹{{ item.price }}</div>
                </div>
              </div>
            </div>

            <div class="buttons" :class="[show == false ? '' : 'backdrop']">
              <b-button class="bttn2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="clickHandler"
                :disabled="isButtonDisabled()">
                Continue to Payment
              </b-button>
            </div>
          </div>
          <!-- right side -->
          <div class="right_" v-if="totalItemsPrice <= maxShippingPackagePrice">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <h4 v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0" class="orange">Recommended
                for Consolidation</h4>
              <h4 v-else class="orange">"Enhance Your Order with Recommendations"</h4>
              <i v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0"
                class="fa-solid fa-circle-info icons__" id="popover-target-1" />
            </div>
            <b-popover target="popover-target-1" triggers="hover" placement="top" style="width: auto;">
              <div style="font-size: 1.5rem; font-weight: bold; margin-bottom: 1rem; color: #150035;">Color Indicators on
                the Progress Bar</div>
              <div>At YIC, we simplify your shopping experience with color indicators on the progress bar:</div>
              <b-progress :max="1000" style="width:100%; margin-top: 1rem; margin-bottom: 1rem; height: 0.7rem;">
                <b-progress-bar value="200" style="background-color: #e43535;"></b-progress-bar>
                <b-progress-bar value="500" style="background-color: #2ead23;"></b-progress-bar>
                <b-progress-bar value="300" style="background-color: #0b6802;"></b-progress-bar>
              </b-progress>
              <div style="display: flex;">
                <div class="rectangle_dimensions">
                  <div class="rectangle_1"></div>
                </div>
                <div>Indicates a 200g buffer weight added by YIC to accommodate minor variations.</div>
              </div>
              <div style="display: flex;">
                <div class="rectangle_dimensions">
                  <div class="rectangle_2"></div>
                </div>
                <div>Represents consolidation items in your cart.</div>
              </div>
              <div style="display: flex;">
                <div class="rectangle_dimensions">
                  <div class="rectangle_3"></div>
                </div>
                <div>Signals fulfillment based on the considered weight.</div>
              </div>
            </b-popover>
            <div v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0">You're just {{ diff * 1000
            }}gms away from
              filling a {{ consideredWeight }} kg consolidated package and saving on shipping costs.</div>
            <div v-else>Discover items carefully curated to complement your selection. Explore and elevate your shopping
              experience. Thank you for choosing YIC!</div>
            <div v-if="yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0" class="progress_bar_text">
              <b-progress :max="max" :precision="2" show-value class="progress_bar" style="width:100%;">
                <b-progress-bar :value="buffer" style="width:10%; background-color: #e43535;"></b-progress-bar>
                <b-progress-bar :value="packageItemsWeight"
                  style="width:50%; background-color: #2ead23;"></b-progress-bar>
                <b-progress-bar :value="cartItemsWeightInKg" :style="dynamicStyle()"
                  style="background-color: #0b6802;"></b-progress-bar>
              </b-progress>
              <span style="font-weight: bold;">({{ totalWeight / 1000 }}/{{ max }})KG</span>
            </div>
            <CategoryButtons v-if="!yourCartItems.has(value.id)" :shippingPackageId="value.id"
              :remainingWeight="diff * 1000" />
            <CategoryButtons v-else :shippingPackageId="value.id"
              :remainingWeight="((Math.ceil(allItemsWeight) - allItemsWeight).toFixed(2)) * 1000 - 200" />

            <Items v-if="!yourCartItems.has(value.id)" :shippingPackageId="value.id" :remainingWeight="diff * 1000" />
            <Items v-else :shippingPackageId="value.id"
              :remainingWeight="((Math.ceil(allItemsWeight) - allItemsWeight).toFixed(2)) * 1000 - 200" />
          </div>
          <div class="right_2" v-else-if="totalItemsPrice > maxShippingPackagePrice && !isItemsReviewed">
            <div class="package_limit_exceeded">
              <div style="color: #FF6B6B; font-size: 1.5rem; font-weight: bold;">Oops! Shipping Limit Reached</div>
              <div><img src="./ShippingLimitReachedImg.svg" /></div>
              <div style="padding: 1rem;">
                <div>
                  You've reached the maximum allowed courier shipment amount as per our guidelines. To continue shipping
                  items, please consider consolidating your package.
                </div>
              </div>
              <div class="package_limit_exceeded_btns">
                <b-button class="border_button rev_btn" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="reviewItemsCalled">
                  Review Items
                </b-button>
              </div>
            </div>
          </div>
          <div class="right_3" v-if="totalItemsPrice > maxShippingPackagePrice && isItemsReviewed">
            <div>
              <div style="color: #FF6B6B; font-size: 1.4rem; font-weight: bold;">Review package valuation</div>
              <div style="font-size: 1rem;">Shipping value per package is maximum of <span style="font-weight: 500;">₹{{maxShippingPackagePrice}}</span>. Please review.</div>
              <br />
              <div style="font-size: 1rem;">Shipping value is the value which one can claim if the package is lost in transit and also this value will be submitted for customs documentation.</div>
            </div>
            <div class="size__">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div class="text_3">Edit Existing Items</div>
                <div @click="viewAllItems" style="cursor: pointer;"><i class="fa-regular fa-pen-to-square"></i></div>
              </div>
              <div class="display_row">
                <div>Total Items</div>
                <div>{{ value.selectedItems.length }}</div>
              </div>
              <div class="display_row">
                <div>Existing Items Value</div>
                <div>₹ {{ existingItemsPrice }}</div>
              </div>
            </div>
            <div class="size__">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div class="text_3">Edit Cart Items</div>
                <div @click="viewCartItems" style="cursor: pointer;"><i class="fa-regular fa-pen-to-square"></i></div>
              </div>
              <div class="display_row">
                <div>Total Items</div>
                <div>{{ yourCartItems.has(value.id) && yourCartItems.get(value.id).length > 0 ?
                  yourCartItems.get(value.id).length : 0 }}</div>
              </div>
              <div class="display_row">
                <div>Cart Items Value</div>
                <div>₹ {{ cartItemsValue }}</div>
              </div>
              <hr class="custom_hr">
              <div class="display_row">
                <div>Total</div>
                <div style="color: #FF6B6B; font-weight: bold;">₹ {{ totalItemsPrice }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-collapse :id="id__" v-if="!status && show" class="collapse" style="display: block">
        <div>
          <ConsolidatedItems :id="id__" />
        </div>
      </b-collapse>
    </div>

    <VolumetricWeightModal ref="vWeightModal" />
    <ItemsModal ref="allItemsModal" :id="id__" />
    <CartItemsModal ref="cartItemsModal" :val="yourCartItems.has(value.id) && yourCartItems.get(value.id).length>0 ? yourCartItems.get(value.id) : []" :shippingPackageId="value.id"/>
  </div>
</template>

<script>
import ConsolidatedItems from "./ConsolidatedItems.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import { BCollapse, BButton, VBToggle, BCard, BProgress, BProgressBar } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BLink,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BOverlay,
  BTooltip,
} from "bootstrap-vue";
import Vue from 'vue'
import VueMq from 'vue-mq'
import CategoryButtons from './RecommendedItemsStore/CategoryButtons.vue';
import Items from './RecommendedItemsStore/Items.vue';
import VolumetricWeightModal from './VolumetricWeightModal.vue';
import ItemsModal from './ItemsModal.vue';
import CartItemsModal from './CartItemsModal.vue'
import { BPopover } from "bootstrap-vue";
import isoCountries from 'i18n-iso-countries';

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  components: {
    BRow,
    ConsolidatedItems,
    BCard,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    VBToggle,
    BOverlay,
    BTooltip,
    BProgress,
    CategoryButtons,
    CategoryButtons,
    Items,
    VolumetricWeightModal,
    ItemsModal,
    BProgressBar,
    BPopover,
    CartItemsModal
  },
  props: ["value", 'reviewItems'],
  data() {
    return {
      picked: "",
      showDetails: false,
      show: false,
      hover: false,
      remainingWeight: 0,
      itemsWeight: 0,
      consideredWeight: 0,
      max: 0,
      totalWeight: 0,
      cartItemsWeight: 0,
      cartItemsWeightInKg: 0,
      allItemsWeight: 0,
      addedItemsWeightDiff: 0,
      sumArray: [],
      sum: 0,
      oldValue: 0,
      newValue: 0,
      count: 0,
      packageItemsWeight: 0,
      diff: 0,
      buffer: 0.2,
      isMaxChanged: false,
      isYourCartItemsChanged: false,
      oldWeight: 0,
      newWeight: 0,
      totalOccupiedWidth: 0,
      price: 0,
      totalItemsPrice: 0,
      existingItemsPrice: 0,
      cartItemsPrice: 0,
      isItemsReviewed: false,
      cartItemsValue: 0,
    };
  },

  computed: {
    ...mapState({
      getDetails: (state) => {
        return state.payments.Details;
      },
      yourCartItems: (state) => {
        return state.storeShoppingCartItems.yourCartItems;
      },
      finalProducts: state => {
        return state.storeShoppingCartItems.finalProductsInPackage;
      },
      progressBarValue: state => {
        return state.storeShoppingCartItems.progressBarValue;
      },
      extraWeight: state => {
        return state.storeShoppingCartItems.extraWeight;
      },
      shippingPartnersDetails: state => {
        return state.storeShoppingCartItems.shippingPartnersDetails;
      },
      maxShippingPackagePrice: state => {
        return state.userItems.priceValidation;
      },
      currUser(state) {
       return state.login.currUser;
      },
    }),
    id__() {
      return `${this.value.id}`;
    },
    status() {
      return this.value.paymentStatus === "Ready For Payment";
    },
  },
  methods: {
    ...mapActions({
      setshippingPartner: "shippingPartner/setShippingPartner",
      getItemsInShippingPackage: "storeShoppingCartItems/getItemsInShippingPackage",
      deleteFromCart: "storeShoppingCartItems/deleteFromCart",
      calculateFinalShippingPriceOfPackage: "storeShoppingCartItems/calculateFinalShippingPriceOfPackage",
      getPriceValidation: "userItems/getPriceValidation",
      addItemToShippingPackage: "storeShoppingCartItems/addItemToShippingPackage",
    }),
    ...mapMutations({
      setAdditionalCharges: "payments/SET_ADDITIONAL_CHARGES",
      setExtraWeight: "storeShoppingCartItems/SET_EXTRA_WEIGHT",
    }),
    shippingPartner(val) {
      this.picked = val;
    },
    formatDate(date) {
      let datee = new Date(date);
      let monthNameShort = datee.toLocaleString('default', { month: 'short' });
      const [year, month, day] = date.split("-");
      return [day, monthNameShort, year].join("-");
    },
    async clickHandler() {
      await this.setshippingPartner({
        id: this.value.id,
        selectedPartner: this.picked
      });
      this.$router.push({
        name: "payment",
        params: { id: this.value.id, shippingPartner: this.picked },
      });
      this.setAdditionalCharges(0);
    },
    showItemsInPackage() {
      this.show = !this.show;
    },
    showVolumetricWeightModal() {
      this.$refs.vWeightModal.showModal();
    },
    shortName(name) {
      let str = name;
      if (str.length > 20) return str.slice(0, 20) + '...'
      return name
    },
    showAddBtn(item){
      return ((this.totalItemsPrice < this.maxShippingPackagePrice) && ((item.variant[0].inventoryQuantity - item.quantity)>0))
    },
    shortName2(name){
      let str = name;
      if (str.length > 10) return str.slice(0, 10) + '...'
      return name
    },
    async removeItems(item) {
      const id = item.id
      await this.deleteFromCart(id)
      // await this.fetchProducts({ shippingPackageId: this.value.id })
      await this.getItemsInShippingPackage({ shippingPackageId: this.value.id, getQuantity: false })
      // this.finalProducts.splice(0,0)
    },
    viewAllItems() {
      this.$refs.allItemsModal.showModal();
    },
    dynamicStyle() {
      const remainingSpace = parseFloat(this.max - 0.2 - this.packageItemsWeight).toFixed(2);
      this.oldWeight = this.cartItemsWeightInKg
      const percentageC = parseFloat(this.oldWeight / remainingSpace).toFixed(2) * 100;
      const clampedPercentageC = Math.min(percentageC, 100);
      const ans = parseFloat((0.4 * clampedPercentageC / 100)).toFixed(2)
      return {
        width: ans < 0.2 ? '20%' : ans * 100 + '%',
      };
    },
    changeMaxValue() {
      this.isMaxChanged = true;
    },
    calcPrice(item) {
      let temp = item && item.variant ? (item.variant[0].priceOffer ? item.variant[0].priceOffer : item.variant[0].priceOriginal ? item.variant[0].priceOriginal : 0) : 0
      return temp * item.quantity
    },
    reviewItemsCalled() {
      this.isItemsReviewed = true
    },
    isButtonDisabled() {
      return this.picked && (this.totalItemsPrice>this.maxShippingPackagePrice)
    },
    viewCartItems() {
      this.$refs.cartItemsModal.showModal();
    },
    async handleItemsInShippingPackage(action, item){
        if(action == 'increase'){
          item.quantity = item.quantity + 1;
        }
        else if(action == 'decrease'){
          item.quantity = item.quantity - 1;
        }
        const userId = this.currUser ? this.currUser.id : null;
        await this.addItemToShippingPackage({quantity: item.quantity, userId: userId, shippingPackageId: this.value.id, variantId: item.variant[0].id, productId: item.product[0].productId, value: item.variant[0].priceOffer == null ? parseFloat(item.variant[0].priceOriginal) : parseFloat(item.variant[0].priceOffer)});
        await this.getItemsInShippingPackage({shippingPackageId: this.value.id, getQuantity: false})
    },
  },
  async mounted() {
    isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    await this.getPriceValidation()
    await this.getItemsInShippingPackage({ shippingPackageId: this.value.id, getQuantity: false })
    let weight1 = this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length > 0 ? this.yourCartItems.get(this.value.id).map(item => {
      return item.variant[0].shippingWeight * item.quantity
    }) : 0
    this.cartItemsWeight = weight1.length > 0 && weight1.reduce((accumulator, curValue) => accumulator + curValue, 0)
    this.cartItemsWeightInKg = this.cartItemsWeight / 1000
    this.packageItemsWeight = parseFloat(this.value.weight > this.value.volumetricWeight ? this.value.weight : this.value.volumetricWeight)
    this.allItemsWeight = parseFloat(this.packageItemsWeight + this.cartItemsWeightInKg).toFixed(2)
    this.totalWeight = this.allItemsWeight
    if (this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length > 0) {
      this.totalWeight = parseFloat(this.allItemsWeight * 1000 + 200).toFixed(2);
    }
    else {
      this.totalWeight = parseFloat(this.allItemsWeight * 1000).toFixed(2);
    }
    if (this.consideredWeight == this.allItemsWeight) {
      this.diff = 0
    }
    else if ((this.consideredWeight - this.allItemsWeight) > 0.2) {
      this.diff = parseFloat(this.consideredWeight - this.allItemsWeight - 0.2).toFixed(2);
    }
    this.consideredWeight = parseInt(Math.ceil(parseFloat(this.totalWeight) / 1000))
    this.max = this.consideredWeight
    if (!isNaN(this.totalWeight) && !isNaN(this.value.volumetricWeight)) {
      await this.calculateFinalShippingPriceOfPackage({ weight: this.totalWeight / 1000, volumetricWeight: parseFloat(this.value.volumetricWeight), shippingPackageId: this.value.id, country: isoCountries.getAlpha2Code(this.value.selectedAddress[0].country, "en") })
    }
    let priceOfPackageItems = this.value.selectedItems.map(item => {
      return item.value
    })
    priceOfPackageItems = priceOfPackageItems.reduce((acc, curVal) => acc + curVal, 0)
    this.existingItemsPrice = priceOfPackageItems
    let pricesOfCartItems = this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length > 0 && this.yourCartItems.get(this.value.id).map(item => {
      return (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity
    })
    pricesOfCartItems = pricesOfCartItems.length > 0 ? pricesOfCartItems.reduce((acc, val) => acc + val, 0) : 0
    this.cartItemsPrice = pricesOfCartItems
    let valueOfCartItems = this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length>0 ? this.yourCartItems.get(this.value.id).map(item => {
      return item.value
    }) : []
    this.cartItemsValue = valueOfCartItems.length>0 ? valueOfCartItems.reduce((acc, val) => acc+val, 0) : 0
    this.totalItemsPrice = priceOfPackageItems + this.cartItemsValue


    const tarea = document.getElementById('autoResizeTextarea');
        // Listen for input events (e.g., typing)
        tarea.addEventListener('input', function () {
            this.style.height = 'auto'; // Reset the height to auto
            this.style.height = this.scrollHeight + 'rem'; // Set the height to match the content
        });
  },
  watch: {
    yourCartItems: {
      async handler(nVal, oVal) {
        if (nVal != oVal) {
          console.log("yourCartItems called inside if");
          let weight1 = nVal.has(this.value.id) && nVal.get(this.value.id).length > 0 ? nVal.get(this.value.id).map(item => {
            return item.variant[0].shippingWeight * item.quantity
          }) : 0
          this.cartItemsWeight = weight1.length > 0 && weight1.reduce((accumulator, curValue) => accumulator + curValue, 0)
          this.cartItemsWeightInKg = this.cartItemsWeight / 1000
          this.allItemsWeight = parseFloat(parseFloat(this.value.weight > this.value.volumetricWeight ? this.value.weight : this.value.volumetricWeight) + this.cartItemsWeight / 1000).toFixed(2);
          if (this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length > 0) {
            this.totalWeight = parseFloat(parseFloat(this.allItemsWeight) * 1000 + 200).toFixed(2);
          }
          else {
            this.totalWeight = parseFloat(this.allItemsWeight * 1000).toFixed(2);
          }
          this.consideredWeight = parseInt(Math.ceil(parseFloat(this.totalWeight) / 1000))
          if (this.consideredWeight == this.allItemsWeight) {
            this.diff = 0
          }
          else if ((this.consideredWeight - this.allItemsWeight) > 0.2) {
            this.diff = parseFloat(this.consideredWeight - this.allItemsWeight - 0.2).toFixed(2);
          }
          this.max = this.consideredWeight;
          if (!isNaN(this.totalWeight) && !isNaN(this.value.volumetricWeight)) {
            await this.calculateFinalShippingPriceOfPackage({ weight: this.totalWeight / 1000, volumetricWeight: parseFloat(this.value.volumetricWeight), shippingPackageId: this.value.id, country: isoCountries.getAlpha2Code(this.value.selectedAddress[0].country, "en") })
          }
          this.isYourCartItemsChanged = true
          let priceOfPackageItems = this.value.selectedItems.map(item => {
            return item.value
          })
          priceOfPackageItems = priceOfPackageItems.reduce((acc, curVal) => acc + curVal, 0)
          this.existingItemsPrice = priceOfPackageItems
          let pricesOfCartItems = this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length > 0 && this.yourCartItems.get(this.value.id).map(item => {
            return (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity
          })
          pricesOfCartItems = pricesOfCartItems.length>0 ? pricesOfCartItems.reduce((acc, val) => acc + val, 0) : 0
          this.cartItemsPrice = pricesOfCartItems
          let valueOfCartItems = this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length>0 ? this.yourCartItems.get(this.value.id).map(item => {
            return item.value
          }) : []
          this.cartItemsValue = valueOfCartItems.length>0 ? valueOfCartItems.reduce((acc, val) => acc+val, 0) : 0
          this.totalItemsPrice = priceOfPackageItems + this.cartItemsValue
        }
      },
      deep: true
    },
    async max(nVal, oVal) {
      if (nVal != oVal) {
        await this.calculateFinalShippingPriceOfPackage({ weight: this.totalWeight / 1000, volumetricWeight: parseFloat(this.value.volumetricWeight), shippingPackageId: this.value.id, country: isoCountries.getAlpha2Code(this.value.selectedAddress[0].country, "en") })
        this.changeMaxValue()
      }
    },
    isMaxChanged(nVal, oVal) {
      if (nVal == true && nVal != oVal) {
        setTimeout(() => {
          this.isMaxChanged = false
        }, 3000)
      }
    },
    isYourCartItemsChanged(nVal, oVal) {
      if (nVal == true && nVal != oVal) {
        setTimeout(() => {
          this.isYourCartItemsChanged = false
        }, 3000)
      }
    },
    value: {
      handler(nVal, oVal) {
        if(nVal!=oVal){
          let priceOfPackageItems = this.value.selectedItems.map(item => {
            return item.value
          })
          priceOfPackageItems = priceOfPackageItems.reduce((acc, curVal) => acc + curVal, 0)
          this.existingItemsPrice = priceOfPackageItems
          let pricesOfCartItems = this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length > 0 && this.yourCartItems.get(this.value.id).map(item => {
            return (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity
          })
          pricesOfCartItems = pricesOfCartItems.length>0 ? pricesOfCartItems.reduce((acc, val) => acc + val, 0) : 0
          this.cartItemsPrice = pricesOfCartItems
          let valueOfCartItems = this.yourCartItems.has(this.value.id) && this.yourCartItems.get(this.value.id).length>0 ? this.yourCartItems.get(this.value.id).map(item => {
            return item.value
          }) : []
          this.cartItemsValue = valueOfCartItems.length>0 ? valueOfCartItems.reduce((acc, val) => acc+val, 0) : 0
          this.totalItemsPrice = priceOfPackageItems + this.cartItemsValue
        }
      },
      deep: true
    }
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
};
</script>
<style scoped>
@keyframes blink {

  0%,
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.blinker {
  color: #10a531;
  animation: blink 1s;
  animation-iteration-count: 3;
}

.blinker2 {
  color: #10a531;
  animation: blink 1s;
  animation-iteration-count: 3;
}

.root_main {
  background-color: #f8f8f8;
  color: #000000;
}

.shippingPartner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 10px;
  background-color: white;
  border: 1px solid #D9D9D9;
  border-radius: 0.5rem;
}

.vl {
  border-left: 1px solid #bcbcbc;
  height: 25px;
}

.white {
  color: white;
}

.root1 {
  background-color: #f8f8f8;
  min-width: 1021px;
  min-height: 118px;
  padding: 2rem;
  display: flex;
  align-items: center;
}

.root-box {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  background: #ffffff;
  height: 53px;
  padding: 2rem;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
  cursor: pointer;
}

.root-box>div {
  max-height: 26px;
  padding: 0 0.3rem;
}

.blue {
  font-weight: 500;
  color: #4385f5;
}

.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.special-border {
  border-right: 1px solid #c9c9c9;
}

.orange {
  font-weight: 500;
  color: #e87613;
}

.your_cart_items_text {
  font-size: 1rem;
  font-weight: 500;
}

.green {
  font-weight: 500;
  color: #10a531;
}

.details {
  margin-left: auto;
}

/* collapse designs! */
.bttn2 {
  background: #4385f5;
  color: #ffffff;
  min-width: 160px;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.buttons {
  margin-top: 1rem;
}

.shippingDetails {
  /* margin-top:70px; */
}

.rroot {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 2rem;
  padding-top: 0;
}

.bttn1 {
  background: #d9e7fd;
  color: #4385f5;
  min-width: 160px;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #414141;
}

.h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.p {
  width: 256px;
  height: 60px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #555555;
  margin-bottom: 80px;
}

.list2 {
  margin-bottom: 0.5rem;
}

.val_list2 {
  color: #000000;
  font-weight: 500;
}

.left_ {
  width: 50%;
  border-right: 1px solid #c9c9c9;
  padding: 0 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
}

.right_ {
  padding: 0 1rem;
  width: 50%;
  padding-right: 1rem;
}

.right_>* {
  margin-bottom: 1rem;
}

.content_ {
  font-size: 13px;
  width: 374px;
  height: 60px;
  line-height: 20px;
}

.vw {
  font-weight: 500;
  line-height: 20px;
}

.lst {
  display: flex;
  gap: 0.5rem;
  text-align: center;
  width: 400px;
  height: 50px;
}

.lst-1 {
  border-bottom: 1px solid #4b2d2d;
}

.box_image {
  width: 290.31px;
  height: 209.79px;
  background-image: url("../../../assets/yicimages/dashboard/box.png");
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.checkBoxes {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.6rem;
  width: 359px;
  height: 38px;
  margin-top: 1.5rem;
}

.label_ {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0.6rem 0;
}

.name {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #414141;
}

.label_ div {
  padding: 0 1rem;
}

.special-border-2 {
  border-right: 1px solid #bcbcbc;
}

.selected {
  border: 1px solid #4385f5;
}

.readyforshipping {
  top: 0;
  left: 1;
  z-index: 3;
  position: absolute;
  width: 100%;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.upper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.overlap {
  position: relative;
  width: 100%;
}

.key_list2 {
  color: black;
}

.backdrop {
  position: relative;
  opacity: 0.4;
}

.card-text-orange {
  color: black;
  background-color: #FEF6EF;
}

.card-text-green {
  color: black;
  background-color: #F7FFF8;
}

.your_cart_items_image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img_container {
  width: 3rem;
  height: 3rem;
}

.hover-info:hover {
  transform: scale(1.2);
}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 20rem;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 40%;
  left: 50%;
  margin-left: -70px;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}

.card-content {
  margin-top: 1rem;
  font-size: 12px;
}

.total_items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.packageinfo_ {
  margin-bottom: 1rem;
}

.d_address {
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #D9D9D9;
  resize: none;
  overflow: hidden;
  min-height: 1rem;
}

.deliveryAddress {
  margin-bottom: 1rem;
}

.your_cart_items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: white;
  border-radius: 0.5rem;
  border: 1.5px solid #D9D9D9;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
}

.icons__ {
  color: #6E6E6E;
  cursor: pointer;
}

.your_cart {
  margin-bottom: 1rem;
}

.your_cart>* {
  margin-bottom: 0.5rem;
}

.f_size {
  font-size: 13px;
}

.progress_bar {
  height: 1rem;
  width: 100%;
  margin-right: 0.5rem;
}

.no_items_text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #e87613;
}

.no_items_card {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 0.5rem;
}

.no_items_card>* {
  margin: 0.5rem;
}

.progress_bar_text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.info_box {
  padding: 1rem;
  color: #FFB000;
  background-color: #FFF6E0;
  border: 1px solid #FFB000;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.rectangle_1 {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #e43535;
  color: #e43535;
  margin-top: 1rem;
}

.rectangle_2 {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #2ead23;
  color: #2ead23;
  margin-top: 1rem;
}

.rectangle_3 {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #0b6802;
  color: #0b6802;
  margin-top: 1rem;
}

.rectangle_dimensions {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.y_cart>* {
  margin-bottom: 0.5rem;
}

.package_limit_exceeded {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 60%;
  margin-bottom: 40%;
}

.package_limit_exceeded>* {
  margin-bottom: 1rem;
}

.right_2 {
  padding: 0 1rem;
  width: 50%;
  padding-right: 1rem;
}

.right_3 {
  padding: 0 1rem;
  width: 50%;
  padding-right: 1rem;
}

.right_3>* {
  margin-bottom: 5rem;
}

.right_2>* {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.border_button {
  border-radius: 2rem;
  width: 100%;
}

.rev_btn {
  background: #FFF;
  color: #4385f5;
  border: 1px solid #4385f5;
}

.package_limit_exceeded_btns {
  width: 100%;
}

.text_3 {
  font-size: 1.5rem;
  color: #414141;
  font-weight: bold;
}

.size__ {
  font-size: 1.2rem;
}

.custom_hr {
  height: 2px;
  color: #c9c9c9;
}

.display_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.qty_rect{
  border: 1px solid #555555;
  min-width: 4rem;
  min-height: 2rem;
}
.qty_handler{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: #b1abab;
}
.qty_handler_2{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f0ecec;
  color: #6E6E6E;
}

@media screen and (max-width: 1200px) {
  .card {
    padding: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5vh;
    width: 100%;
    min-height: 85px;
  }

  .left_ {
    width: 100%;
    border-right: none;
    padding: 0rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
  }

  .right_ {
    width: 100%;
    border-right: none;
    padding: 0rem;
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .view-item {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .packageNo {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .date {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }

  .root_1 {
    padding: 1rem;
    padding-top: 0rem;
  }

  .tooltip1 .tooltiptext {
    visibility: hidden;
    width: 20rem;
    border-radius: 6px;
    padding: 1px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 20%;
    left: 50%;
    margin-left: -260px;
  }

  .card-content {
    margin-top: 1rem;
    font-size: 1rem;
  }

  .card-text-orange {
    color: black;
    background-color: #FEF6EF;
    font-size: 1.2rem;
  }

  .card-text-green {
    color: black;
    background-color: #F7FFF8;
    font-size: 1.2rem;
  }

  .continue-btn {
    width: 100%;
    background: #4385f5;
    color: white;
    margin-bottom: 0.7rem;
  }

  .shipping-details-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    visibility: visible;
    padding-right: 0;
  }

  .shippingPartner {
    margin-bottom: 1rem;
  }

  .blue {
    font-weight: 500;
    color: #4385f5;
    margin-left: 0.3rem;
  }

  .your_cart_items_text {
    font-size: 0.9rem;
  }
  .package_limit_exceeded {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .right_2 {
    padding: 0 0 0 1rem;
    width: 100%;
    padding-right: 1rem;
  }
  .right_3{
    width: 100%;
    margin-top: 0;
  }
  .right_3 > *{
    margin-bottom: 1.2rem;
  }
  .size__{
    font-size: 1rem;
  }
  .text_3{
    font-size: 1.2rem;
  }

}

@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root1 {
    min-width: 65em;
  }
}</style>

<style></style>