<template>
  <b-modal
    ref="changeProfile"
    id="modal-center"
    centered
    title="Profile Picture Change"
    ok-title="Yes"
    cancel-title="No"
    @ok="changeProfileHandler"
  >
    <b-card-text> Do you wish to change your profile picture ? </b-card-text>
    <input
      v-on:change="setProfile"
      id="selectProfilePic"
      type="file"
      class=""
      name=""
      accept="image/png, image/gif, image/jpeg"
    />
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { uploadFile } from "../../s3upload/index";
export default {
  methods: {
    ...mapActions({
        getS3Cred: "uploadProfileToS3/getS3Cred",
        storeProfilePicToDatabase: "uploadProfileToS3/setProfilePicToDatabase",
      getProfilePicFromDB: "uploadProfileToS3/getProfilePicFromDB"

    }),
    ...mapMutations({
        setUrl: "uploadProfileToS3/setUrl",
    }),
    dummy() {
      this.$refs.changeProfile.show();
    },
    changeProfileHandler() {
      console.log("changeProfileHandler called");
      document.getElementById("selectProfilePic").click();
      this.getProfilePicFromDB
    },
    async setProfile(event) {
      const selectedFile = event.target.files;
      const fileName = selectedFile[0].name;
      await this.getS3Cred(fileName);
      const result = this.getS3CredFromStore;
      console.log("Result = ", result);
      await uploadFile(
        result,
        function() {},
        fileName,
        "uploads",
        selectedFile[0].type,
        selectedFile[0]
      ).then(async (result) => {
        console.log("Got back result after upload -> ", result.data[0].accessUrl);
        await this.storeProfilePicToDatabase(result.data[0].accessUrl);
        await this.getProfilePicFromDB();
      });
    },
  },
  computed: {
    ...mapState({
        getS3CredFromStore: (state) => state.uploadProfileToS3.S3Cred
    })
  }
};
</script>

<style>
#selectProfilePic {
  display: none;
  visibility: none;
}
</style>
