<template>
  <div class="modal-wrapper">
    <b-modal ref="ISD2" title="Indian Sender Detail" ok-only ok-title="Confirm" @ok="ok" centered>
      <!-- <img src="../../assets/yicimages/dashboard/no-image-found.png" alt=""> -->
      <b-form class="mx-2">
        <b-form-group class="l" label="First Name" label-for="senderPhone">
          <b-input-group class="input-group-merge">
            <b-form-input id="senderPhone" v-model="fName" name="fullName" type="text" placeholder="" required="true" />
          </b-input-group>
          <small v-if="fName == ''" class="text-danger">This field is required</small>
        </b-form-group>
        <b-form-group class="r" label="Last Name" label-for="senderPhone">
          <b-input-group class="input-group-merge">
            <b-form-input id="senderPhone" v-model="lName" name="fullName" type="text" placeholder="" required="true" />
          </b-input-group>
          <small v-if="lName == ''" class="text-danger">This field is required</small>
        </b-form-group>
        <b-form-group label="Address Line 1" label-for="senderPhone">
          <b-input-group class="input-group-merge">
            <b-form-input id="senderPhone" v-model="al1" name="fullName" type="text" placeholder="" required="true" />
          </b-input-group>
          <small v-if="al1 == ''" class="text-danger">This field is required</small>
        </b-form-group>
        <b-form-group label="Address Line 2" label-for="senderPhone">
          <b-input-group class="input-group-merge">
            <b-form-input id="senderPhone" v-model="al2" name="fullName" type="text" placeholder="" required="true" />
          </b-input-group>
          <small v-if="al2 == ''" class="text-danger">This field is required</small>
        </b-form-group>
        <b-row>
          <b-col>

            <div class="loader">
              <BSpinner v-if="isLoading"></BSpinner>
            </div>
            <img v-if="editedFrontUrl" crossorigin="anonymous" width="100%" height="75%" :src="editedFrontUrl" />
            <img v-else-if="s3FrontUrl && !isLoading" crossorigin="anonymous" width="100%" height="75%"
              :src="s3FrontUrl" />

            <img v-else-if="!s3FrontUrl && !isLoading" width="100%" height="75%"
              src="../../assets/yicimages/dashboard/no-image-found.png" alt="not-found" />
            <div class="select_edit_btn">
              <b-button size="sm" variant="outline-primary" v-ripple="'rgba(255, 255, 255, 0.15)'" class="mt-1 retake-btn"
                v-on:click="retakeHandler(3)">Select Photo</b-button>
              <b-button size="sm" variant="outline-primary" v-ripple="'rgba(255, 255, 255, 0.15)'" class="mt-1 retake-btn"
                @click="editPhotoBtnClicked('front')">Edit Photo</b-button>
            </div>
            <input v-on:change="retakePhoto" id="inputTag3" type="file" class="fileInput3" name="frontAC"
              accept="image/png, image/gif, image/jpeg" />
            <b-row>

              <b-col>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <div class="loader">
              <BSpinner v-if="isLoading2"></BSpinner>
            </div>
            <img v-if="editedBackUrl" crossorigin="anonymous" width="100%" height="75%" :src="editedBackUrl" />
            <img v-else-if="s3BackUrl && !isLoading2" crossorigin="anonymous" width="100%" height="75%"
              :src="s3BackUrl == '' ? notFound : s3BackUrl" />
            <img v-else-if="!s3BackUrl && !isLoading2" width="100%" height="75%"
              src="../../assets/yicimages/dashboard/no-image-found.png" alt="not-found" />
            <div class="select_edit_btn">
              <b-button size="sm" variant="outline-primary" v-ripple="'rgba(255, 255, 255, 0.15)'" class="mt-1 retake-btn"
                v-on:click="retakeHandler(4)">Select Photo</b-button>
              <b-button size="sm" variant="outline-primary" v-ripple="'rgba(255, 255, 255, 0.15)'" class="mt-1 retake-btn"
                @click="editPhotoBtnClicked('back')">Edit Photo</b-button>
            </div>
            <input v-on:change="retakePhoto" id="inputTag4" type="file" class="fileInput4" name="backAC"
              accept="image/png, image/gif, image/jpeg" />
          </b-col>
        </b-row>
        <br />
        <br />
        <b-form-group label="Phone Number" label-for="account-Phone">
          <b-input-group class="input-group-merge">
            <b-form-input v-model="contactNumber" type="number" placeholder="" required />
          </b-input-group>
          <small v-if="isValidPhoneNumber == false" class="text-danger">Invalid Phone Number</small>
        </b-form-group>
        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
      </b-form>
      <template #modal-footer="">
        <b-button class="confirm2" @click="ok">
          <div class="confirmText2">Confirm</div>
        </b-button>
      </template>
    </b-modal>
    <EditModal :aadharSide="aadharSide" :image="aadharCardImage" @croppedImage="croppedImage" ref="editAadharCardModal" />
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Image from "@/views/components/image/Image.vue";
import notFound from "../../assets/yicimages/dashboard/no-image-found.png";
import { mapActions, mapMutations, mapState } from "vuex";
import { uploadFile } from "../../s3upload/index";
import EditModal from './EditAadharCardPhoto.vue';

export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    Image,
    notFound,
    BSpinner,
    EditModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      isLoading2: false,
      displayFrontUrl: "",
      displayBackUrl: "",
      fullName: "",
      fName: "",
      lName: "",
      al1: "",
      al2: "",
      contactNumber: "",
      isValidPhoneNumber: true,
      showModal: false,
      aadharCardImage: '',
      aadharSide: '',
      editedFrontUrl: '',
      editedBackUrl: ''
    };
  },
  methods: {
    ...mapActions({
      getS3Cred: "uploadToS3/getS3Cred",
      getDetails: "senderDetails/getSenderDetails",
      createDetails: "senderDetails/createSenderDetails",
      updateDetails: "senderDetails/updateSenderDetails",
      getAadharFromDatabase: "uploadToS3/getAadharFromDatabase",
    }),
    ...mapMutations({
      updateDetailsInStore: "senderDetails/setDetails",
      setUrls: "uploadToS3/setUrls",
    }),
    close: () => {
      this.$emit("close");
      this.$root.$emit('closeModal')
    },
    dummy() {
      // this.ID = id;
      // this.fetchDeliveryAddress({ ID: this.ID });
      this.$refs.ISD2.show();
    },
    ok() {
      const obj = {
        firstName: this.fName,
        lastName: this.lName,
        addressLine1: this.al1,
        addressLine2: this.al2,
        aadharFrontUrl: this.s3FrontUrl,
        aadharBackUrl: this.s3BackUrl,
        phoneNumber: this.contactNumber,
        id: this.senderId,
      };
      if (this.fName != '' && this.lName != '' && this.al1 != '' && this.al2 != '' && this.s3BackUrl != '' && this.s3FrontUrl != '' && this.isValidPhoneNumber) {
        if (this.addressExists && this.isValidPhoneNumber) {
          this.updateDetails(obj);
        }
        else if (this.isValidPhoneNumber) {
          this.createDetails(obj);
          this.getDetails();
        }
        this.$refs.ISD2.hide();
        this.$root.$emit('closeModal')
      }
    },
    retakeHandler: function (n) {
      const inputTag = "inputTag" + n;
      console.log(inputTag);
      document.getElementById(inputTag).click();
    },
    async croppedImage(val) {
      if (val.aadharSide == 'frontAC') {
        this.editedFrontUrl = val.image
        this.dataURItoBlob(val.image);
      }
      else if(val.aadharSide == 'backAC'){
        this.editedBackUrl = val.image
        this.dataURItoBlob(val.image);
      }
    },
    async retakePhoto(event) {
      if (event && event.target) {
        console.log("%%%");
        console.log(event.target.name);
        console.log(event.target.files);
        const selectedFile = event.target.files;
        const fileName = selectedFile[0].name;
        await this.getS3Cred(fileName);
        const result = this.getS3CredFromStore;
        console.log("Result in m2 : ", result);
        await uploadFile(
          result,
          function () { },
          fileName,
          "uploads",
          selectedFile[0].type,
          selectedFile[0]
        ).then((result) => {
          console.log("**** ", result.data[0].accessUrl);
          if (event.target.name === "frontAC") {
            this.isLoading = true;
            setTimeout(() => {
              this.isLoading = false;
            }, 4000);
          }
          if (event.target.name === "backAC") {
            this.isLoading2 = true;
            setTimeout(() => {
              this.isLoading2 = false;
            }, 4000);
          }
          const O = {
            url: result.data[0].accessUrl,
            f: event.target.name,
          };
          this.setUrls(O);
        });
      }
      else {
        console.log("event", event);
        console.log(event.name);
        const fileName = event.name;
        await this.getS3Cred(fileName);
        const result = this.getS3CredFromStore;
        console.log("Result in m2 : ", result);
        await uploadFile(
          result,
          function () { },
          fileName,
          "uploads",
          event.type,
          event
        ).then((result) => {
          console.log("**** ", result.data[0].accessUrl);
          
          const O = {
            url: result.data[0].accessUrl,
            f: this.aadharSide,
          };
          this.setUrls(O);
        });
      }
    },
    editPhotoBtnClicked(val) {
      this.$refs.editAadharCardModal.showModal();
      if (val == 'front') {
        this.aadharCardImage = this.s3FrontUrl
        this.aadharSide = 'frontAC'
      }
      else {
        this.aadharCardImage = this.s3BackUrl
        this.aadharSide = 'backAC'
      }
    },
    async dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(',')[1]);

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var fileName=''
      if(this.aadharSide == 'frontAC'){
        fileName = this.senderFullName + " aadharCardFront";
      }
      else{
        fileName = this.senderFullName + " aadharCardBack";
      }
      console.log(new File([ab], fileName, { type: 'image/jpeg' }));
      await this.retakePhoto(new File([ab], fileName, { type: 'image/jpeg' }));
    }
  },
  async mounted() {
    await this.getDetails();
    await this.getAadharFromDatabase();
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    this.isLoading2 = true;
    setTimeout(() => {
      this.isLoading2 = false;
    }, 3000);
    this.fName = this.firstName;
    this.lName = this.lastName;
    this.al1 = this.addrL1;
    this.al2 = this.addrL2;
    this.contactNumber = this.contact;
  },
  computed: {
    ...mapState({
      senderId: (state) => {
        return state.senderDetails.uid;
      },
      addressExists: (state) => {
        return state.senderDetails.addressExists;
      },
      addrL1: (state) => {
        return state.senderDetails.addrL1;
      },
      addrL2: (state) => {
        return state.senderDetails.addrL2;
      },
      senderFullName: (state) => {
        return state.senderDetails.fName + " " + state.senderDetails.lName;
      },
      firstName: (state) => {
        return state.senderDetails.fName;
      },
      lastName: (state) => {
        return state.senderDetails.lName;
      },
      aadharFrontUrl: (state) => {
        return state.senderDetails.aadharFront;
      },
      aadharBackUrl: (state) => {
        return state.senderDetails.aadharBack;
      },
      contact: (state) => {
        return state.senderDetails.contact;
      },
      s3FrontUrl: (state) => {
        return state.uploadToS3.aadharFrontUrl;
      },
      s3BackUrl: (state) => {
        return state.uploadToS3.aadharBackUrl;
      },
      getS3CredFromStore: (state) => state.uploadToS3.S3Cred,
    }),
  },
  watch: {
    contactNumber(newVal, oldVal) {
      const regex = /^[6-9]\d{9}$/
      if (newVal.match(regex)) {
        this.isValidPhoneNumber = true;
      }
      else {
        this.isValidPhoneNumber = false;
      }
    }
  }
};
</script>

<style>
.loader {
  text-align: center;
}

.retake-btn {
  background-color: #eaf2ff !important;
  outline: none !important;
  border: none !important;
  color: #4385f5 !important;
  border-radius: 0%;
  width: 100% !important;
}

.upload-btn {
  background-color: #4385f5 !important;
  color: #ffffff !important;
  outline: none !important;
  border-radius: 0% !important;
}

.input-group-merge {
  width: 70%;
  margin-bottom: 0;
}

.confirm2 {
  border-radius: 0% !important;
  /* margin: 0 auto !important; */
  margin-bottom: 5% !important;
  margin-right: 56% !important;
  background-color: #4385f5 !important;
  width: 40%;
  height: 40px;
  text-align: center;
  padding: 0 auto !important;
}

.confirmText2 {
  color: #ffffff;
  height: 100%;
  text-align: center;
  padding-top: 1% !important;
  cursor: pointer;
}

.address {
  display: block;
  width: 90%;
  height: 100%;
  border: 1px solid #e87613;
}

/* 
.input-container {
  margin: 10% auto;
  height: 40px;
  margin-left: 7%;
} */

.location-fields {
  padding: 0 auto;
  margin: auto;
  width: 20px;
}

.location {
  margin-top: 5%;
}

.location-container {
  width: 50%;
  display: inline-block;
  padding: 0 5%;
}

#pin {
  width: 100%;
}

#city {
  width: 100%;
}

#state {
  width: 100%;
}

#country {
  width: 100%;
}

#inputTag3 {
  display: none;
  visibility: none;
}

#inputTag4 {
  display: none;
  visibility: none;
}

label {
  /* margin-left:; */
  color: #e87613;
}

.modal-wrapper {
  width: 500px;
}

/* .ISD {
  font-weight: 1000;
  margin: 5% 0 2% 5%;
} */
/* .aadharCardDet {
  margin: 0 0 2% 5%;
} */
/* .contactNo {
  margin-left: 5%;
} */
.LaunchModal {
  margin: 5% 0 0 5%;
  width: 200px;
  height: 30px;
  padding: 10px 0 auto 10px;
  background-color: #7367f0;
  border-radius: 5px;
  text-align: center;
  font-weight: 1000;
  padding-top: 4px;
  color: #ebe9fd;
}

label {
  cursor: pointer;
}

.select_edit_btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.select_edit_btn>* {
  margin-right: 0.5rem;
}
</style>
