<template>
  <div class="root_">
    <div v-if="getPackagesDelivered() == 0">
      <EmptyState />
    </div>
    <div v-else v-for="(item, indx) in getAllPackageDetails" :key="indx" class="root__box_">
        <PackagesDeliveredComp v-if="item.shippingStatus === 'Delivered'" :value="item" />        
    </div>
  </div>
</template>

<script>
import PackagesDeliveredComp from "./PackagesDeliveredComp.vue";
import { mapMutations, mapActions, mapState } from "vuex";
import EmptyState from "./EmptyStatePackagesDelivered.vue";

export default {
    components: {
        EmptyState,
        PackagesDeliveredComp
    },
    data() {
    return {};
  },
  
  computed: {
    ...mapState({
      getAllPackageDetails: (state) => {
      return state.shippingPackages.itemsToBeShipped;
    },
    }),
  },
  methods: {
    ...mapActions({
      getShippedItems: 'shippingPackages/getShippedItems',
    }),
    ...mapMutations({
      setMyOrdersNavbar: 'routesStore/SET_MY_ORDERS_NAVBAR',
      setSidebarRoute: 'routesStore/SET_SIDEBAR',
      setNavbarRoute: 'routesStore/SET_NAVBAR'
    }),
    getPackagesDelivered(){
     const arr = Object(this.getAllPackageDetails.filter(item => item.shippingStatus === 'Delivered'))
     return arr.length;
    }
  },
  mounted() {
    this.getShippedItems()
    this.setMyOrdersNavbar('packagesDelivered')
    this.setSidebarRoute('productsInWarehouse')
    this.setNavbarRoute('dashboardLanding')
    this.getPackagesDelivered()
  },
}
</script>

<style scoped>
@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root_ {
    width: 65em;
  }
}
</style>