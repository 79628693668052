<template>
  <div class="root-myOrder">

    <!-- <div class="sidebar"></div> -->
    <div class="right">
      <!-- Empty Suite Condition -->
      <div>
        <!-- navbar -->
        <div class="nav_navbar">
          <Navbar
            :activeNavItemId="activeNavItemId"
            @setNavItemId="setNavItemId"
          />
        </div>
        <!-- tabs rendering -->
        <div class="xyz__">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductsInWarehouse from "./ProductsInWarehouse.vue";
import Navbar from "./Navbar.vue";
import ConsolidationStatus from "./ConsolidationStatus.vue";
import ReadyForShipping from "./ReadyForShipping.vue";
import PackageTracking from "./PackageTracking.vue";
import EmptyState from "./EmptyState.vue";
import { mapMutations, mapActions, mapState } from "vuex";
import PackagesDelivered from "./PackagesDelivered.vue"

export default {
  components: {
    EmptyState,
    ProductsInWarehouse,
    PackageTracking,
    Navbar,
    ReadyForShipping,
    ConsolidationStatus,
    PackagesDelivered
  },
  data() {
    return {
      activeNavItemId: 0,
      routePath: ''
    }
  },
  methods: {
    ...mapMutations({
      setMyOrdersNavbar: 'routesStore/SET_MY_ORDERS_NAVBAR'
    }),
    setNavItemId(indx) {
      this.activeNavItemId = indx
      if(indx == 0){
        this.$router.push({name: 'productsInWarehouse'})
      }
      else if(indx == 1) {
        this.$router.push({name: 'consolidationStatus'})
      }
      else if(indx == 2) {
        this.$router.push({name: 'readyForShipping'})
      }
      else if(indx == 3) {
        this.$router.push({name: 'packageTracking'})
      }
      else if(indx == 4) {
        this.$router.push({ name: 'packagesDelivered'})
      }
    },
    ...mapActions({
      getLength: 'userItems/showUserItems'
    }),
  },
  computed: {
    ...mapState({
      getMyOrdersNavbarRoute: state => {
        return state.routesStore.myOrdersNavbar
      }
    }),
  },
  mounted() {
    this.getLength({ currentPage: 1, pageSize: 25, index: 1 });
    this.routePath = this.$route.name
    
  },
  watch: {
    getMyOrdersNavbarRoute(newVal, oldVal ) {
    if(newVal === 'productsInWarehouse') {
      this.activeNavItemId = 0
    }
    else if(newVal === 'consolidationStatus') {
      this.activeNavItemId = 1
    }
    else if(newVal === 'readyForShipping') {
      this.activeNavItemId = 2
    }
    else if(newVal === 'packageTracking') {
      this.activeNavItemId = 3
     }
    }
  }
};
</script>

<style scoped>
.root-myOrder {
  /* padding: 2.4rem 6rem; */
  padding: 1rem;
  /* padding-right: 8rem; */
  background-color: #fff;
  width: 73em;
  height: 100%;
  width: fit-content;
}

.nav_navbar {
  margin-bottom: 2rem;
}

.xyz__ {
  width: 73em;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .root-myOrder {
    padding: 0.5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    background-color: #fff;
    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
  }
  
  .nav_navbar {
    margin-bottom: 1rem;
  }
  
  .xyz__ {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    padding-top: 0px;
  }
}
@media screen and (max-width: 1314px) {
  .root-myOrder{
    /* padding: 2rem 0; */
  }
}
@media screen and (max-width: 1257px) {
  .root-myOrder{
    /* padding: 2rem 0; */
  }
}
@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root-myOrder {
    width: 65em;
  }
  .xyz__ {
  width: 65em;
  height: 100%;
  }
}
</style>