<template>
  <div class="root_main">
    <div class="root1" v-if="$mq === 'largeDevices'">
      <!-- {{value}} -->
      <div class="root-box" v-b-toggle="`${id__}`">
        <div class="packageNumber blue special-border">Package {{ value.packageNo }}</div>
        <div class="shipping-partner special-border">Shipping Partner: <span class="blue">{{value.selectedShippingPartner[0].shippingpartners}}</span></div>
        <div class="number-of-items special-border">No. Of Items <span class="blue">{{value.numberOfItems}}</span></div>
        <div class="track_id">TrackingId: <span class="blue">{{value.trackingId}}</span></div>

        <div class="view-item blue" @click="showItemsInPackage">View Items
            <span v-if="show == false" class="blue"> <i class="fa-solid fa-circle-chevron-down"></i></span>
            <span v-else class="blue"> <i class="fa-solid fa-circle-chevron-up"></i></span>
        </div>
      </div>
      <b-collapse :id="id__" v-if="show" class="collapse" style="display: block">
         <div>
          <ConsolidatedItems :id="id__" />
         </div>
      </b-collapse>
      <div class="button">
      <b-button
          class="bttn2"
          variant="no-color"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="track(value.trackingId,value.selectedShippingPartner[0].shippingpartners)">
        Track Your Shipment
      </b-button>
      <div class="tracking_status">Delivery Status: <span v-if="value.shippingStatus === 'Completed'" class="orange">In-Progress</span>
      <span v-if="value.shippingStatus === 'Delivered'" class="green">Delivered</span></div>
      <b-button 
          class="bttn2"
          @click="downloadinvoice(value.shippingInvoiceURL)"
          variant="no-color"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'">
          
          Download Shipping Invoice
      </b-button>
      </div>
    <!-- collapse part -->
    
    </div>

    <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
      <div class="card">
        <div class="left">
          <div class="packageNo blue">{{ value.packageNo }}</div>
          <div class="number-of-items">{{value.selectedShippingPartner[0].shippingpartners}}</div>
          <!-- <div class="shipping-partner-name">{{value.trackingId}}</div> -->
          <a
          class="bttn__2"
          @click="downloadinvoice(value.shippingInvoiceURL)"
          variant="no-color"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'">
          
          Download Invoice
         </a>
        </div>
        <div class="right">
          <div class="trackPackage">
            <b-button class="bttn__1" variant="no-color" @click="track(value.trackingId,value.selectedShippingPartner[0].shippingpartners)">Track Package</b-button>
          </div>
          <div class="view-item blue" @click="showItemsInPackage">View Items
            <span v-if="show == false" class="blue icon__"> <i class="fa-solid fa-circle-chevron-down"></i></span>
            <span v-else class="blue icon__"> <i class="fa-solid fa-circle-chevron-up"></i></span>
          </div>
        </div>
      </div>
      <b-collapse :id="id__" v-if="!status && show" class="collapse" style="display: block">
         <div>
          <ConsolidatedItems :id="id__" />
         </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import { BCollapse, BButton, VBToggle, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BLink,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import ConsolidatedItems from "./ConsolidatedItems.vue"
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  components: {
    BRow,
    BCard,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    VBToggle,
    ConsolidatedItems
  },
  props: ["value"],
  data() {
    return {
      show: false
    };
  },
  
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    ...mapActions({
      getTrackingUrl: 'tracking/getTrackingUrl',
      getShippingPackageUrl: 'tracking/getShippingPackageUrl',
    }),
    handleClick() {},
    async track(trackingId,selectedShippingPartner){
      await this.getTrackingUrl({shippingPartner: selectedShippingPartner })
      let link = "";
      if(this.value.selectedShippingPartner[0].shippingpartners != 'UPS'){
        link = this.trackingUrls + trackingId;
      }
      else{
        link = this.trackingUrls;
      }
      link = link.replace('{tracking_number}',trackingId)
      window.open(link, "_blank");
    },
    async downloadinvoice(val){
      
      window.open(val, "_blank");
    },
    
    showItemsInPackage() {
      this.show = !this.show;
    },
  },
  computed: {
    ...mapState({
      trackingUrls: (state) => {
       return state.tracking.trackingUrl;
      },
      shippingPackageURL: (state) => {
       return state.tracking.shippingPackageURL;
      },
    }),
    mounted(){

    },
    id__() {
      return `${this.value.id}`;
    },
    
  },
};
</script>
<style scoped>
.root1 {
  min-width: 1021px;
  min-height: 118px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.root_1 {
  padding: 0;
  padding-top: 2rem;
  background: #f8f8f8;
}
.root_main {
  background-color: #f8f8f8;
  margin-bottom: 3rem;
}
.root-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
  height: 53px;
  padding: 2rem;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: #414141;
  background: #ffffff;
}
.root-box > div {
  max-height: 26px;
}
.blue {
  font-weight: 500;
  color: #4385f5;
}
.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 0 13rem;
}
.special-border {
  border-right: 1px solid #c9c9c9;
}
.details {
  margin-left: auto;
}
.button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bttn2 {
  background: #4385f5;
  color: #ffffff;
  min-width: 160px;
  display: flex;
  justify-content: flex-start;
  margin-top: 2vh;
}
.track_id {
  font-size: 1rem;
  padding-right: 0;
}
.inside {
  display: flex;
  align-content: center;
  padding-bottom: 2.5rem;
  gap: 2rem;
}
.download-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.packageNumber {
  padding-right: 2vw;
}
.shipping-partner {
  padding-right: 2vw;
}
.number-of-items {
  padding-right: 2vw;
}
.collapse {
  background: #f8f8f8;
  width: 100%;
}
.tracking_status {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 4vh;
}
.orange {
  color: #e87613;
}
.green {
  color: #10a531;
}

@media screen and (max-width: 1200px) {
  .blue{
    color: #4385f5;
  }
  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.7rem;
    width: 100%;
    min-height: 85px;
    margin-bottom: 1.5vh;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .shipping-partner-name {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
  .root_main {
    margin-bottom: 0px;
  }
  .trackPackage {
    display: flex;
    justify-content: flex-end;
  }
  .track {
    width: 5vw;
    height: 5vh;
  }
  .root_1 {
    padding: 0rem 0.5rem 0rem 0.5rem;
  }
  .bttn__1 {
    background: #4385f5;
    color: #ffffff;
    padding: 0.5rem;
    border: none;
  }
  .bttn__2 {
    color: #4385f5;
    text-decoration: underline;

  }
  .view-item {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }
  .icon__ {
    margin-left: 0.2rem;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root1 {
    min-width: 65em;
  }
}

</style>