<template>
  <div>
    <b-modal
      id="modal-1"
      ref="returnMessage2"
      title="Disclaimer!"
      ok-only
      ok-title="Finish"
      @ok="goToProductInWarehouse()"
      centered
    >
      <p class="text"
        >We have initiated the return process. To check the status of return please go to the <span class="blue" @click="goToReturnAndAbandon()">Returns And Abandons</span> section under My Dashboard.</p
      >
      <p class="text bottom"> Home  >  My Dashboard  >  Return & Abandon </p>
    </b-modal>
  </div>
</template>
    
<script>
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "ReturnMessage2",
  components: {},
  computed: mapState({
    
  }),
  methods: {
    ...mapMutations({

    }),
    ...mapActions({
      removeItem: 'userItems/removeReturnedItem'
    }),
    showModal() {
      this.$refs.returnMessage2.show();
    },
    async goToProductInWarehouse() {
      this.$router.push({name: 'productsInWarehouse'})
    },
    goToReturnAndAbandon() {
      this.$router.push({ name : 'return-abandon' })
    }
  },
};
</script>
<style scoped>
#modal-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  border-radius: 0%;
}
.text {
  color: #414141;
  font-weight: 500;
}
.btn-primary {
  background-color: #4385f5 !important;
}
.blue{
    color: #4385F5;
    cursor: pointer
}
.bottom {
  color: #A1A1A1
}
</style>