<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-modal
        id="modal-1"
        ref="userdetails"
        cancel-title="Close"
        ok-title="Save Changes"
        @ok.prevent="addData()"
        centered
        title="Enter fields to change"
      >
        <b-form-group
          id="input-group-first-name"
          class="form-group"
          label="First Name"
          label-for="firstName"
        >
          <validation-provider
            #default="{ errors }"
            name="FirstName"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="first-name"
                v-model="firstName"
                name="firstname"
                required
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          id="input-group-last-name"
          class="form-group"
          label="Last Name"
          label-for="lastName"
        >
          <validation-provider
            #default="{ errors }"
            name="LastName"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="lname"
                v-model="lastName"
                :type="text"
                required
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          id="input-group-email"
          class="form-group"
          label="Email"
          label-for="email"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="e-mail"
              v-model="email"
              :type="email"
              required
              readonly
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          id="input-group-contact"
          class="form-group"
          label="Contact"
          label-for="account-phone"
        >
          <!-- <validation-provider #default="{ errors }" name="Contact Number" rules="required">
        <b-input-group class="input-group-merge">
          <b-form-input id="cnumber" v-model="phone" :type="tel" required />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
          <div class="phNum">
            <VuePhoneNumberInput v-model="phone" v-on:update="trial" />
          </div>
          <br />
          <small class="text-success">{{ messages }}</small>
        </b-form-group>
        <!-- <b-button @click="show=true">Click Here To Change Password</b-button> -->
        <!-- <b-modal  v-model="show" id="changePass">
      <b-form-group
        id="input-group-password"
        class="form-group"
        label="Old Password"
        label-for="old-password"
      >
        <b-input-group class="input-group-merge">
          <b-form-input id="oldpassword" type="password" required 
          placeholder="Old Password"
          v-model="oldPassWord"/>
        </b-input-group>
      </b-form-group>

      <b-form-group
        id="input-group-password"
        class="form-group"
        label="New Password"
        label-for="new-password"
      >
        <b-input-group class="input-group-merge">
          <b-form-input id="newpassword" type="password" required 
          placeholder="New Password"
          v-model="newPassWord"/>
        </b-input-group>
      </b-form-group>
      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="primary"
            style="margin:10px"
           
            @click="changePassword"
          >
            save changes
          </b-button>
          <b-button
            variant="primary"
            style="margin:10px"
            @click="show=false"
          >
            close
          </b-button>
        </div>
      </template>
    </b-modal> -->
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { BFormGroup, BButton, BFormInput, BInputGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapMutations, mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VuePhoneNumberInput from "vue-phone-number-input";

export default {
  name: "UserDetails",
  components: {
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isPhNumValid: null,
      yourValue: null,
      messages: "",
      show: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      newPassword: "",
      oldPassword: "",
      // oldPasswordMatch: true,
      required,
    };
  },
  // computed: mapState({
  //   previousPassword: state => {
  //     return state.login.authPayload.password
  //   }
  // }),
  computed: {
    ...mapState({
      userfirstname: (state) => state.editedProfile.firstName,
      userlastname: (state) => state.editedProfile.lastName,
      useremail: (state) => state.editedProfile.email,
      usercontact: (state) => state.editedProfile.contactNumber,
    }),
  },
  methods: {
    trial(event) {
      console.log(" Event : ", event);
      // console.log(" yourValue: ", this.yourValue);
      this.isPhNumValid = event.isValid;
      this.phone = event.formattedNumber;
    },
    close() {
      this.closeModal = true;
      this.$root.$emit('closeModal')
    },
    ...mapMutations({
      setFirstName: "editedProfile/SET_FIRSTNAME",
      setLastName: "editedProfile/SET_LASTNAME",
      setEmail: "editedProfile/SET_EMAIL",
      setContact: "editedProfile/SET_CONTACT",
    }),
    ...mapActions({
      updateDetails: "editedProfile/updateDetails",
      getUserDetails: "editedProfile/getUserDetails",
      // changePass: "editedProfile/changePassword"
    }),
    //  changePassword(){
    //    console.log({newPass: this.newPassword, oldPass: this.oldPassword, email: this.useremail})
    //   if(newPassword && oldPassword){
    //   this.changePass({newPass: this.newPassword, oldPass: this.oldPassword, email: this.useremail})
    // }},
    addData() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && this.isPhNumValid) {
          this.setFirstName(this.firstName);
          this.setLastName(this.lastName);
          // this.setEmail(this.email);
          this.setContact(this.phone);
          // if(this.oldPassword != this.previousPassword) {
          //   this.oldPasswordMatch = !this.oldPasswordMatch
          // }
          this.getUserDetails();
          this.updateDetails({
            firstName: this.firstName,
            lastName: this.lastName,
            contact: this.phone,
          });
          this.getUserDetails();
          this.messages = "Changes Done Successfully";
          setTimeout(() => {
            this.messages = "";
          }, 2000);
          this.$refs.userdetails.hide();

          // if(this.oldPasswordMatch == true){
          // }
        }
      });
    },

    close: () => {
      this.$emit("close");
      this.messages = "";
    },
    showModal() {
      this.$refs.userdetails.show();
    },
  },

  watch: {
    usercontact: {
      handler(userContact) {
        this.firstName = this.userfirstname;
        this.lastName = this.userlastname;
        this.email = this.useremail;
        this.phone = this.usercontact;
      },
    },
  },
  mounted() {
    this.getUserDetails();
    this.firstName = this.userfirstname;
    this.lastName = this.userlastname;
    this.email = this.useremail;
    this.phone = this.usercontact;
  },
};
</script>

<style scoped>
.form1 {
  height: 50%;
  width: 50%;
}

#input-group-first-name__BV_label_,
#input-group-last-name__BV_label_,
#input-group-email__BV_label_,
#input-group-contact__BV_label_ {
  color: #e87613;
}

/* .input-group-merge{
  border:1px solid #E87613;
} */

.phNum {
  display: inline-block;
  width: 70%;
}

label {
  position: relative;
  top: -1px;
  left: 10px;
}
.form-control {
  border: 1px solid #e87613;
}
input[type="text"]:focus {
  border: none;
  outline: 1px solid #e87613;
}
</style>
