<template>
    <div>
      <b-tabs>
        <b-tab title="All" active @click="changeTab(0)">
          <div class="df2" v-if="finalVariants.has(shippingPackageId) && finalVariants.get(shippingPackageId).length>0">
            <Item :val="n" v-for="n in finalVariants.get(shippingPackageId)" :key="n.id" :shippingPackageId="shippingPackageId" :remainingWeight="rWeight"/>
          </div>
        </b-tab>
        <b-tab title="Within Weight Range" @click="changeTab(1)">
          <div class="df2" v-if="finalVariants.has(shippingPackageId) && finalVariants.get(shippingPackageId).length>0">
            <Item :val="n" v-for="n in finalVariants.get(shippingPackageId)" :key="n.id" :shippingPackageId="shippingPackageId" :remainingWeight="remainingWeight"/>
          </div>
          <div v-else>
            No items in this category within this weight range.
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </template>
  <script>
  import { BModal, VBModal, BAlert, BPaginationNav, BPagination, BCardText, BTabs, BTab } from "bootstrap-vue";
  import BCardCode from "@core/components/b-card-code";
  import Ripple from "vue-ripple-directive";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations, mapActions, mapState } from "vuex";
  import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
  } from "bootstrap-vue";
  import CategoryButtons from "./CategoryButtons.vue";
  import Item from "./Item.vue";
  
  export default {
    components: {
      Item,
      BPaginationNav,
      BPagination,
      BCardCode,
      BButton,
      BModal,
      BAlert,
      BRow,
      BImg,
      BCol,
      BLink,
      BForm,
      BCardText,
      BCardTitle,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BInputGroup,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
      CategoryButtons,
      BTabs,
      BTab
    },
    directives: {
      Ripple,
    },
    props: ['shippingPackageId', 'remainingWeight'],
    data() {
      return {
        modifiedProducts: [],
        perPage: 12,
        currentPageInternal: null,
        isLoading: false,
        fProducts: '',
        rWeight: 0,
      };
    },
    methods: {
      ...mapActions({
        fetchProducts: "storeProducts/fetchProductsForShippingPackage",
        setCurrentPage: "storeProducts/setCurrentPage",
        getTempVariant: "storeProducts/getTempVariantAccordingToWeight",
        getVariantsForShippingPackage: "storeProducts/getVariantsForShippingPackage"
      }),
      ...mapMutations({
        updateFinalProducts: "storeShoppingCartItems/SET_FINAL_PRODUCTS_IN_PACKAGE",
        updateActiveTab: "storeShoppingCartItems/SET_ACTIVE_TAB"
      }),
      setIsLoading(val) {
        this.isLoading = val;
      },
      async setFinalProducts(newcurrentPage = -1, nw = null) {
        // check if products are there..
        if (!this.fProducts) return;
  
        this.modifiedProducts = this.fProducts;
        if (!this.modifiedProducts) return;// no modified products..
  
        let x = [];
        for (let i = 0; i < this.modifiedProducts.length; i++) {
          x.push(this.modifiedProducts[i]);
        }
  
        this.updateFinalProducts({shippingPackageId:this.shippingPackageId,products:x})
      },
      async changeTab(val){
        await this.updateActiveTab(val)
      }
    },
    computed: mapState({
      products(state) {
        return state.storeProducts.productsForShippingPackage;
      },
      selectedCategory(state) {
        return state.storeProducts.selectedCategoryForShippingPackage;
      },
      selectedSubCategory(state) {
        return state.storeProducts.selectedSubCategory;
      },
      totalItems(state) {
        return this.modifiedProducts ? this.modifiedProducts.length : 0;
      },
      currentPage(state) {
        this.currentPageInternal = state.storeProducts.currentPage;
        return state.storeProducts.currentPage;
      },
      numberOfPages() {
        const value = Math.ceil(this.totalItems / 12);
        return value ? value : 1;
      },
      finalProducts(state) {
        return state.storeShoppingCartItems.finalProductsInPackage
      },
      finalVariants(state){
        return state.storeProducts.variantsForShippingPackage
      },
      yourCartItems(state){
        return state.storeShoppingCartItems.yourCartItems
      },
      activeTab(state) {
        return state.storeShoppingCartItems.activeTab
      }
    }),
    watch: {
      async yourCartItems(nVal, oVal){
        if(nVal!=oVal){
          if(this.activeTab == 0){
            await this.getVariantsForShippingPackage({shippingPackageId: this.shippingPackageId, weight: 0})
          }
          else {
            await this.getVariantsForShippingPackage({shippingPackageId: this.shippingPackageId, weight: this.remainingWeight})
          }
        }
      },
      async activeTab(nVal, oVal){
        if(nVal!=oVal){
          if(nVal == 0){
            await this.getVariantsForShippingPackage({shippingPackageId: this.shippingPackageId, weight: 0})
          }
          else{
            await this.getVariantsForShippingPackage({shippingPackageId: this.shippingPackageId, weight: this.remainingWeight})
          }
        }
      }
    },
    
  };
  </script>
  <style scoped>
  .root_items {
    padding: 0 8rem;
    padding-top: 2rem;
    padding-right: 3rem;
    margin-bottom: 3rem;
  }
  
  .df2 {
    height: 35rem;
    overflow-y: auto;
  }

  /* styling for chrome, safari */
/* Set the width and height of the scroll thumb */
div::-webkit-scrollbar {
  width: 12px; /* Width of the vertical scrollbar */
  height: 0; /* Height of the horizontal scrollbar */
}

/* Customize the colors of the scroll thumb and track */
div::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

div::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

div::-webkit-scrollbar-track {
  background: #eee; /* Color of the scrollbar track */
  border-radius: 6px; /* Rounded corners for the track */
}

/* Style for Firefox */
/* This example customizes the scrollbars for both horizontal and vertical scrollbars */

/* Set the width and height of the scroll thumb */
*{
  scrollbar-width: thin; /* Width of the scrollbar (thin or auto) */
  scrollbar-color: #888 #eee; /* Color of the scroll thumb and track */
}

/* Customize the colors of the scroll thumb and track */
div::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

div::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

div::-webkit-scrollbar-track {
  background: #eee; /* Color of the scrollbar track */
  border-radius: 6px; /* Rounded corners for the track */
}
  
  .__pagination {
    margin-top: 1.4rem;
  }
  
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -100px;
    width: 48px;
    height: 48px;
    border: 5px solid #4385f5;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .backdrop {
    /* position: fixed; */
    /* background: rgba(0, 0, 0, 0.5); */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
  
  
  /*========================= Media Queries (Small devices) =========================*/
  @media screen and (max-width: 600px) {
    .root_items {
      padding: 0.5rem;
      margin-bottom: 3rem;
      overflow: hidden;
  
    }
  
    .df {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.2rem
    }
    div::-webkit-scrollbar {
      display: none;
    }
  }
  
  @media screen and (max-width: 400px) {
    .df {
      gap: 0.1rem
    }
    div::-webkit-scrollbar {
      display: none;
    }
  }
  
  @media screen and (max-width: 330px) {
  
    .df {
      display: grid;
      grid-template-columns: 1fr;
    }
    div::-webkit-scrollbar {
      display: none;
    }
  }
  </style>

<style>
.nav {
  display: flex !important;
  justify-content: flex-end !important;
}
</style>