<template>
  <div>
    <b-modal
      id="modal-1"
      ref="returnMessage"
      title="Disclaimer!"
      ok-title="CONTINUE"
      @ok="showOtherMessage()"
      @cancel="closeModal()"
      centered
    >
      <b-card-text class="text"
        >Please ensure that you have placed a return request at the merchant end where you brought the product.</b-card-text
      >
    </b-modal>
    <ReturnMessage2 ref="returnMessage2"></ReturnMessage2>
  </div>
</template>
  
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import ReturnMessage2 from "./ReturnMessage2.vue"

export default {
  name: "ReturnMessage",
  props: ["value"],
  components: {
    ReturnMessage2
  },
  computed: mapState({}),
  methods: {
    ...mapActions({
      changeReturnStatus: 'userItems/updateReturnStatus'
    }),
    showModal() {
      this.$refs.returnMessage.show();
    },
    closeModal() {
      this.$refs.returnMessage.hide();
    },
    showOtherMessage() {
      this.changeReturnStatus(this.value);
      let element = this.$refs.returnMessage2
      element.showModal()
    }
  },
};
</script>
  
<style scoped>
#modal-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: #414141;
  font-weight: 500;
}
.btn-primary {
  background-color: #4385f5 !important;
}
</style>