<template>
  <div class="root_">
    <div v-if="getItemsInPackageTracking() == 0">
      <EmptyState />
    </div>
    <div v-else v-for="(item, indx) in getAllPackageDetails" :key="indx" class="root__box_">
        <SpecialComp2 v-if="item.shippingStatus === 'Completed'" :value="item" />        
    </div>
  </div>
</template>

<script>
import SpecialComp2 from "./SpecialComp2.vue";
import { mapMutations, mapActions, mapState } from "vuex";
import EmptyState from "./EmptyStatePackageTracking.vue"

export default {
  components: {
    SpecialComp2,
    EmptyState
  },
  data() {
    return {
    };
  },
  
  computed: {
    ...mapState({
      getAllPackageDetails: (state) => {
      return state.shippingPackages.itemsToBeShipped;
    },
    }),
  },
  methods: {
    ...mapActions({
      getShippedItems: 'shippingPackages/getShippedItems',
    }),
    ...mapMutations({
      setMyOrdersNavbar: 'routesStore/SET_MY_ORDERS_NAVBAR',
      setSidebarRoute: 'routesStore/SET_SIDEBAR',
      setNavbarRoute: 'routesStore/SET_NAVBAR'
    }),
    getItemsInPackageTracking(){
     const arr = Object(this.getAllPackageDetails.filter(item => item.shippingStatus === 'Completed'))
     return arr.length;
    }
  },
  mounted() {
    this.getShippedItems()
    this.setMyOrdersNavbar('packageTracking')
    this.setSidebarRoute('productsInWarehouse')
    this.setNavbarRoute('dashboardLanding')
    this.getItemsInPackageTracking();
  },
};
</script>


<style scoped>
@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root_ {
    width: 65em;
  }
}
</style>