<template>
  <div>
    <b-modal
      id="modal-1"
      ref="isd_check"
      title="Important!"
      hide-footer
      centered
      >
      <div class="modal_text">
      <div class="missing_image"><img class="m_img" src = "https://cdn-icons-png.flaticon.com/512/2472/2472903.png"/></div>
      <p class="text">Aadhar card and other details missing for the Indian sender. Please go to <span class="blue" @click="AccountSettings">Account Settings </span>, upload the All Details and return to My Orders to continue with consolidation.</p> 
      </div>
    </b-modal>
  </div>
</template>
    
<script>
import { mapMutations, mapState } from "vuex";

export default {
  components: {
  },
  computed: mapState({
  }),
  methods: {
    ...mapMutations({}),
    showModal() {
      this.$refs.isd_check.show();
    },
    closeModal() {
      this.$refs.isd_check.hide();
    },
    AccountSettings(){
      this.$router.push({name: 'account-settings'});
    }
  },
};
</script>
    
<style scoped>
#modal-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: #414141;
  font-weight: 500;
}
.btn-primary {
  background-color: #4385f5 !important;
}
.blue{
  color: #4385f5;
  cursor: pointer;
}
.missing_image{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-top: 1rem;
}
.m_img{
  width: 4rem;
  height: 4rem;
}
.modal_text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
