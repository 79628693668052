<template>
    <div class="super_root_item">
      <div class="store_item2" v-if="val">
          <div class="img_container2">
           <div class="small_img_2">
             <img class="small-img" :src="val.products[0].productImages[0]" alt="No Image found"/>
           </div>
           <div class="store_middle">
            <div class="store_text p_name_text">{{ shortName(val.products[0].name) }}</div>
            <div class="store_text">{{val.shippingWeight ? val.shippingWeight : 0}} gms</div>
            <div>
                <div class="price2 blue">
                    ₹ {{ (val) ? (val.priceOffer || val.priceOriginal) : "" }} <div class="cut-p"
                      v-if="val && val.priceOffer && val.priceOffer < val.priceOriginal">
                      ₹ {{ val ? val.priceOriginal : "" }}
                    </div>
                </div> 
            </div>
          </div>
        </div>
        <div>
            <div v-if="val.quantity == 0 && !isButtonDisabled" class="add_bttn" @click="addItemsInShippingPackage('increase')">
                <span>+ Add</span>
            </div>
            <div v-else-if="val.quantity == 0 && isButtonDisabled" class="add_bttn_disabled">
                <span>+ Add</span>
            </div>
            <div v-else class="add_bttn_2">
              <div class="add_btn_qty" @click="addItemsInShippingPackage('decrease')">
                <span class="qty_handler">- </span>
              </div>
              <div class="add_btn_qty">
                <span class="qty_text">{{val.quantity}} </span>
              </div>
              <div class="add_btn_qty" @click="addItemsInShippingPackage('increase')" v-if="isQuantityAvailable">
                <span style="margin-right: -0.5rem;" class="qty_handler">+</span>
              </div>
              <div v-else class="add_btn_qty">
                <span style="margin-right: -0.5rem;" class="no_qty_clr">+</span>
              </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { BModal, VBModal, BAlert, BCardText, BOverlay } from "bootstrap-vue";
  import BCardCode from "@core/components/b-card-code";
  import Ripple from "vue-ripple-directive";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations, mapActions, mapState } from "vuex";
  import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
  } from "bootstrap-vue";
  export default {
    components: {
      BCardCode,
      BButton,
      BModal,
      BAlert,
      BRow,
      BImg,
      BCol,
      BLink,
      BForm,
      BCardText,
      BCardTitle,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BInputGroup,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
      BOverlay,
    },
    directives: {
      Ripple,
    },
    props: ["val", "shippingPackageId", "remainingWeight"],
    data() {
      return {
        show: false,
        isButtonDisabled: false,
        isQuantityAvailable: true
      };
    },
    methods: {
      ...mapActions({
        getTempVariant: "storeProducts/getTempVariantAccordingToWeight",
        addItemToShippingPackage: "storeShoppingCartItems/addItemToShippingPackage",
        getItemsInShippingPackage: "storeShoppingCartItems/getItemsInShippingPackage",
        getShippedDetails: "payments/getShippedDetails",
      }),
      shortName(name) {
        let str = name;
        if (str.length > 30) return str.slice(0, 30) + '...'
        return name
      },
      async addItemsInShippingPackage(action){
        this.isButtonDisabled = true
        setTimeout(() => {
          this.isButtonDisabled = false;
        }, 2000)
        if(action == 'increase'){
          this.val.quantity = this.val.quantity + 1;
        }
        else if(action == 'decrease'){
          this.val.quantity = this.val.quantity - 1;
        }
        const userId = this.currUser ? this.currUser.id : null;
        this.isQuantityAvailable = (this.val.inventoryQuantity - this.val.quantity) > 0 ? true : false
        await this.addItemToShippingPackage({quantity: this.val.quantity, userId: userId, shippingPackageId: this.shippingPackageId, variantId: this.val.id, productId: this.val.productId, value: this.val.priceOffer == null ? parseFloat(this.val.priceOriginal) : parseFloat(this.val.priceOffer)});
        await this.getItemsInShippingPackage({shippingPackageId: this.shippingPackageId, getQuantity: false})
      }
    }, 
    mounted(){
      console.log(this.val);
      this.isQuantityAvailable = (this.val.inventoryQuantity - this.val.quantity) > 0 ? true : false
    },
    computed: mapState({
      currUser(state) {
       return state.login.currUser;
      },
    }),
  };
  </script>
  <style scoped>
  .super_root_item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .root_item {
    width: 255px;
    height: max-content;
    background: #ededed;
    display: flex;
    flex-direction: column;
    padding: 1.3rem;
    margin: 1rem 0;
    margin-right: 1rem;
  }
  
  .bttn1 {
    background: #e87613;
    color: #ffffff;
    width: 95px;
    height: 32.93px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .scol {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .i_title {
    max-width: 225px;
    height: 60px;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    /* text-wrap: none; */
    word-wrap: break-word
  }
  
  .img_container2 {
    width: 90%;
    min-height: 4rem;
    display: flex;
  }
  .img_container2>* {
    margin-right: 3rem;
  }

  .small_img_2 {
    width: 4rem;
    height: 4rem;
  }
  
  .fcol {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.2rem 0;
    margin-bottom: 0;
  }
  
  .price2 {
    font-weight: 500;
    font-size: 1rem;
    color: "#0EB100";
  }
  
  .blue {
    color: #4385f5;
  }
  
  .cate {
    font-weight: 400;
    font-size: 12px;
    color: #808080;
  }
  
  .i_rating {
    color: #e87613;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .df {
    padding-top: 0.3rem;
  }
  
  .cut-p {
    color: #9f9f9f;
    text-decoration: line-through;
    font-weight: 500;
    display: inline-block;
  }
  
  .small-img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
  .store_item2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    background: white;
    border-radius: 0.5rem;
    border: 1.5px solid #D9D9D9;
    margin-bottom: 0.5rem;
  }
  .store_middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: -2rem;
  }

  .store_text {
    font-size: 0.9rem;
  }
  .add_bttn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #4385F5;
    border-radius: 2rem;
    padding: 0.3rem;
    min-width: 5rem;
    min-height: 1rem;
    align-items: center;
    margin-top: 2rem;
    color: #4385F5;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .add_bttn_2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid #4385F5;
    border-radius: 2rem;
    padding: 0.2rem;
    min-width: 5rem;
    min-height: 1rem;
    align-items: center;
    margin-top: 2rem;
    color: #4385F5;
    font-size: 0.9rem;
    cursor: pointer;
  }
  .add_btn_qty{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no_qty_clr {
    color: #928e8e;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .add_bttn, .add_bttn_2 > * {
    margin-right: 0.5rem;
  }

.add_bttn_disabled{
    background-color: #92a9cf;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #4385F5;
    border-radius: 2rem;
    padding: 0.3rem;
    min-width: 5rem;
    min-height: 1rem;
    align-items: center;
    margin-top: 2rem;
    color: #FFF;
    font-size: 0.9rem;
    cursor: pointer;
}
  .p_name_text {
    font-weight: bold;
  }

  .qty_handler {
    font-size: 1.2rem;
    color: #000000;
    font-weight: bold;
  }

  .qty_text {
    font-size: 1rem;
    font-weight: bold;
  }
  
  /*========================= Media Queries (Small devices) =========================*/
  @media screen and (max-width: 600px) {
    .root_item {
      width: 100%;
      height: 100%;
      background: #ededed;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  
    .img_container {
      width: auto;
      height: 120px;
      /* background: #ffffff; */
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  
    .i_title{
      height: 90px;
    }
    .store_middle {
        margin-left: 0;
    }
    .img_container2>* {
      margin-right: 1rem;
    }
  }
  
  @media screen and (max-width: 400px) {
    .root_item {
      padding: 1rem;
      width: 100%;
      /* margin: 1rem 0;
      margin-right: 1rem; */
    }
    .store_middle {
        margin-left: 0;
    }
  }
  </style>