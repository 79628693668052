<template>
    <div>
        <b-modal v-if="showModal" ref="editAadharCardPhoto" title="Edit Image Here" centered @cancel="closeModal" ok-only ok-title="Crop Image" @ok="cropImage">
            <div>
                <cropper ref="cropper" class="cropper" :src="image" :stencil-props="{
                    aspectRatio: 1.58
                }" @cropped="handleCroppedImage"></cropper>
            </div>
        </b-modal>
    </div>
</template>
      
<script>
import {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton
} from "bootstrap-vue";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    props: ['image', 'aadharSide'],
    components: {
        BFormGroup,
        BFormInput,
        BInputGroup,
        BButton,
        Cropper
    },
    data() {
        return {
            cropped: null,
        }
    },
    methods: {
        showModal() {
            this.$refs.editAadharCardPhoto.show();
        },
        closeModal() {
            this.$refs.editAadharCardPhoto.hide();
        },
        cropImage() {
            const result = this.$refs.cropper.getResult();
            this.image = result.canvas.toDataURL(
                this.image.type
            )
            this.$emit('croppedImage', { aadharSide: this.aadharSide, image: this.image });
            this.$refs.editAadharCardPhoto.hide();
        },
        handleCroppedImage(data) {
            this.image = data;
        }
    },
};
</script>
      
<style scoped>
#modal-1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    color: #414141;
    font-weight: 500;
}

.btn-primary {
    background-color: #4385f5 !important;
    align-items: center;
}

.input-group-text {
    color: red;
}

.input-group-text:focus-visible {
    border: none;
    border-radius: 0px;
    outline: none;
}

.cropper {
    height: 600px;
    background: #DDD;
}
</style>