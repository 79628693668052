<template>
 <div class="root">
  <div v-if="itemsInUserBin.length == 0">
      <EmptyState />
  </div>
 
  <div v-else>
    <!-- table -->

    <div class="table-section" v-if="$mq === 'largeDevices'">
      <b-table
        sticky-header="400px"
        :hover="true"
        responsive
        :items="itemsInUserBin"
        :fields="fields"
        @row-clicked="itemSelected"
      >
       <template v-for="field in itemsInUserBin"
                 v-slot:cell(whenArrived)="field">
          <div v-if="days(field.item.whenArrived.substring(0,10))>1">{{ days(field.item.whenArrived.substring(0,10)) }} days ago</div>
          <div v-if="days(field.item.whenArrived.substring(0,10))==1">{{ days(field.item.whenArrived.substring(0,10)) }} day ago</div>
          <div v-if="days(field.item.whenArrived.substring(0,10))==0">Today</div>
          
       </template>

       <template v-for="field in itemsInUserBin"
                 v-slot:cell(value)="field" >
          <div class="prepend-value">
            <!-- <div class="prepend">₹</div> -->
            <div class="prepend">&#8377;</div>
            <input class="value" v-model="field.item.value"
            required 
            @change="onChange(field.item)"
            type="number"/>
          </div>
       </template>

       <template v-for="field in itemsInUserBin"
                 v-slot:cell(pictures)="field">
        <img crossorigin="anonymous" class="image" v-if="field.item.itemSource != 'store' && field.item.productpictures.length>0" :src="field.item.productpictures[0].url" />
        <img class="image" v-else-if="field.item.itemSource == 'store' && field.item.productpictures.length>0" :src="field.item.productpictures[0].url" />
        <img class="image" v-else alt="No Image Found" />
       </template>

        <template #cell(consolidate)="row">
          <div class="consolidate__checkbox">
          <b-form-group>
            <b-form-checkbox
              size="lg"
              v-model="row.item.consolidate"
              plain
              @change="select(row.item)">
            </b-form-checkbox>
            
          </b-form-group>
          </div>
        </template>
      </b-table>
      <!-- <div>{{consolidate}}</div> -->
      <div class="demo-inline-spacing select-all">
        <b-form-checkbox-group>
        <b-form-checkbox
          size="lg"
          plain
          v-model="allSelected"
          @change="toggleAll"
        ><div class="allSelectedText">{{ allSelected ? 'Un-select All' : 'Select All' }}</div></b-form-checkbox>
        </b-form-checkbox-group>
      </div>

      <div v-if="allSelected === false && selectedItemsLength.length==0" class="totalSelectedItems">{{consolidate.length}}/{{totalItems}} items selected</div>
      <div v-else class="totalSelectedItems">{{selectedItemsLength.length}}/{{totalItems}} items selected</div>

    </div>

    <!-- Mobile screens -->
    <div v-if="$mq === 'mediumDevices' || $mq === 'smallDevices' " class="table-saw">
    <div v-for="item in itemsInUserBin" class="card item-card">
      <div class="s-card" >
        <div class="image-1"  @click="itemSelected(item)">
          <img crossorigin="anonymous" class="image" v-if="item.productpictures.length>0" :src="item.productpictures[0].url" />
          <img class="image" v-else alt="No Image Found" />
        </div>
        <div class="middle-content"  @click="itemSelected(item)">
          <div>
            <div class="item-name">{{item.itemName}}</div>
            <div class="item-qty">{{item.quantity}}</div>
          </div>
          
          <div class="date-received"> 
          <span v-if="days(item.whenArrived.substring(0,10))>1">{{ days(item.whenArrived.substring(0,10)) }} days ago</span>
          <span v-if="days(item.whenArrived.substring(0,10))==1">{{ days(item.whenArrived.substring(0,10)) }} day ago</span>
          <span v-if="days(item.whenArrived.substring(0,10))==0">Today</span>
          </div>
        </div>
        <div class="column-3">
          <div class="card-value">
           <div class="prepend-value">
            <div class="prepend">₹</div>
            <input class="value" v-model="item.value"
            required 
            @change="onChange(item)"
            type="number"/>
           </div>
          </div>
      
         <div class="c_small_checkbox">
          
            <b-form-checkbox
              v-if="allSelected === false"
              size="lg"
              v-model="item.consolidate"
              plain
              @change="select(item)"
            ></b-form-checkbox>
            <b-form-checkbox
              v-else
              size="lg"
              v-model="allSelected"
              plain
            ></b-form-checkbox>
          
        </div>
      
        </div>
      </div>
    </div>
    
    <div class="lower-content">
    <div class="select-all">
        <b-form-checkbox-group>
        <b-form-checkbox
          size="lg"
          plain
          v-model="allSelected"
          @change="toggleAll">
        <div class="allSelectedText">{{ allSelected ? 'Un-select All' : 'Select All' }}</div>
        </b-form-checkbox>
    </b-form-checkbox-group>
    </div>

    <div v-if="allSelected === false && selectedItemsLength.length==0" class="totalSelectedItems">{{consolidate.length}}/{{totalItems}} items selected</div>
    <div v-else class="totalSelectedItems">{{selectedItemsLength.length}}/{{totalItems}} items selected</div>
    <div class="buttons">
      <b-button
        v-if="allSelected === true"
        class="bttn2"
        variant="no-color"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="consolidateMessage"
      > Consolidate Now
      </b-button>

      <b-button
        v-else
        class="bttn2"
        variant="no-color"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="consolidateMessage"
        :disabled="consolidate.length == 0"
      >
        Consolidate Now
      </b-button>
    </div>
    <!-- <ConsolidateDialog ref="consolidateItemMessage"></ConsolidateDialog>
    <ValuationCheckMessage ref="valuationCheckMessage" /> -->
    <div class="info">Value of the item is used for shipping purposes only, this does not imply the item is insured for this value. Contact support for details around buying extra insurance.</div>
   </div>
  </div>
    <!-- button -->

    <div class="buttons" v-if="$mq === 'largeDevices'">
      <b-button
        v-if="allSelected === true"
        class="bttn2"
        variant="no-color"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="consolidateMessage"
      > Consolidate Now
      </b-button>

      <b-button
        v-else
        class="bttn2"
        variant="no-color"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="consolidateMessage"
        :disabled="consolidate.length == 0"
      >
        Consolidate Now
      </b-button>
    </div>
    <CheckIndianSenderDetailModal ref="isd_check"></CheckIndianSenderDetailModal>
    <ConsolidateDialog ref="consolidateItemMessage"></ConsolidateDialog>
    <ValuationCheckMessage ref="valuationCheckMessage" />
    <div class="info" v-if="$mq === 'largeDevices'">Value of the item is used for shipping purposes only, this does not imply the item is insured for this value. Contact support for details around buying extra insurance.</div>
  </div>

 </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapState, mapMutations } from "vuex";
import { BNav, BNavItem, BNavbarNav } from "bootstrap-vue";
import {
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BCard
} from "bootstrap-vue";

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import ConsolidateDialog from "./ConsolidateMessage.vue";
import ValuationCheckMessage from "./ValuationCheckWarningMessage.vue";
import EmptyState from './EmptyState.vue';
import Vue from 'vue'
import VueMq from 'vue-mq'
import CheckIndianSenderDetailModal from './CheckIndianSenderDetails.vue'
Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BTable,
    BAvatar,
    BBadge,
    BCardBody,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BNav,
    BNavItem,
    BCard,
    BNavbarNav,
    BPagination,
    ConsolidateDialog,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    ValuationCheckMessage,
    EmptyState,
    CheckIndianSenderDetailModal
  },
  data() {
    return {
      activeNavItemId: 0,
      suiteItems: [],
      navItems: [
        { name: "Products in Warehouse" },
        { name: "Consolidation Status" },
        { name: "Ready for Shipping" },
        { name: "Package Tracking" },
      ],
      fields: [
        { label: "", key: "pictures" },
        { label: "Item Name", key: "itemName" },
        "quantity",
        { label: "Date Received", key: "whenArrived" },
        "value",
        { label: "Merchant", key: "itemSource" },
        "consolidate",
      ],
      perPage: 50,
      currentPage: 1,
      allSelected: false,
      val: "",
      selectedItems: [],
      totalValue: 0,
    };
  },
  directives: {
    Ripple,
  },
  computed: mapState({
    itemsInUserBin: (state) => {
      return state.userItems.items;
    },
    totalItems: (state) => {
      return state.userItems.totalItems;
    },
    allUserItems: (state) => {
      return state.userItems.allItems;
    },
    consolidate: (state) => {
      return state.userItems.items.filter((item) => item.consolidate);
    },
    consolidateAll: (state) => {
      return state.userItems.allItems.filter((item) => item.consolidate);
    },
    selectedItemsLength: (state) => {
      return state.userItems.consolidate;
    },
    len: (state) => {
      return state.userItems.selectedItemsList;
    },
    getPriceValidation : state => {
      return state.userItems.priceValidation;
    },
    getSenderDetails: state => {
      const fname = state.senderDetails.fName;
      const lname = state.senderDetails.lName;
      const addrLine1 = state.senderDetails.addrL1;
      const addrLine2 = state.senderDetails.addrL2;
      const cont = state.senderDetails.contact;
      const aadharF = state.senderDetails.aadharFront;
      const aadharB = state.senderDetails.aadharBack;

      return (fname && lname && addrLine1 && addrLine2 && cont && aadharF && aadharB)
    }
  }),
  mounted() {
    this.selectedItems = [];
    this.consolidatedItems(this.selectedItems);
    this.allSelected = false;
    this.showUserItems({
      currentPage: this.currentPage,
      pageSize: this.perPage,
      index: 0,
    });
    this.setSidebarRoute('productsInWarehouse')
    this.setMyOrdersNavbar('productsInWarehouse')
    this.setNavbarRoute('dashboardLanding')
    this.checkPriceValidation()
    this.getSenderDetail();
  },

  methods: {
    ...mapMutations({
      consolidatedItems: "userItems/SET_CONSOLIDATE_ITEMS",
      setQRCodeImageUrl: "userItems/SET_QR_CODE_IMAGE_URL",
      setItemId: "userItems/SET_ITEM_ID",
      countSelectedItems: "userItems/SET_SELECTED_ITEM_LIST",
      setSidebarRoute: 'routesStore/SET_SIDEBAR',
      setMyOrdersNavbar: 'routesStore/SET_MY_ORDERS_NAVBAR',
      setNavbarRoute: 'routesStore/SET_NAVBAR'
    }),
    ...mapActions({
      showUserItems: "userItems/showUserItems",
      updateValue: "userItems/updateValue",
      checkPriceValidation: "userItems/getPriceValidation",
      getSenderDetail: "senderDetails/getSenderDetails",
    }),
    async consolidateMessage() {
      this.consolidatedItems(this.selectedItems);
      const totalValueOfItems = this.selectedItemsLength.map(
        (item) => item.value
      );
      for (let i = 0; i < totalValueOfItems.length; i++) {
        this.totalValue += totalValueOfItems[i];
      }
      if(!this.getSenderDetails){
        this.$refs.isd_check.showModal();
      }
      else if (this.totalValue >= this.getPriceValidation) {
        let element = this.$refs.valuationCheckMessage;
        element.showModal();
        this.totalValue = 0;
      } else {
        let element = this.$refs.consolidateItemMessage;
        element.showModal();
        this.totalValue = 0;
      }
    },
    itemSelected(item) {
      const itemId = item.id;
      this.$router.push({ name: "view-item", params: { id: itemId } });
    },
    showDate(receivedDate) {
      return new Date().getDate() - receivedDate;
    },
    toggleAll(checked) {
      this.allSelected = checked;
      if (checked == true) {
        this.selectedItems = [...this.itemsInUserBin];
        this.selectedItems.forEach((item) => (item.consolidate = checked));
        this.consolidatedItems(this.selectedItems);
      } else {
        this.selectedItems = [];
        this.itemsInUserBin.forEach(item => (item.consolidate = checked))
        this.consolidatedItems(this.selectedItems);
      }
    },
    onChange(val) {
      this.setItemId(val.id);
      const newVal = val.value;
      this.updateValue({ newVal });
    },
    days(date) {
      const currDate = new Date().toISOString().slice(0, 10);
      const date1 = new Date(currDate);
      const date2 = new Date(date);
      const diffTime = Math.abs(date1 - date2);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    select(item) {
      let isPresent = false;
      if (this.selectedItems.find((v) => v.id === item.id)) {
        isPresent = true;
      }
      if (item.consolidate && isPresent === false) {
        this.selectedItems.push(item);
      } else {
        const index = this.selectedItems.indexOf(item);
        this.selectedItems.splice(index, 1);
        this.allSelected = false;
        item.consolidate = false;
      }
    },
  },
  watch: {
    selectedItems(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.allSelected = false;
      } else if (newValue.length === this.totalItems) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
  },
};
</script>

<style scoped>
.active-text {
  color: #4385f5;
}
.root {
  background-color: #fff;
  height: 100%;
  width: 73em;
}
.info {
  font-size: 1.2rem;
  font-weight: 500;
  color: #000000;
  margin-top: 4rem;
}
.bttn2 {
  background: #4385f5;
  color: #ffffff;
  min-width: 160px;
}
.buttons {
  margin-top: 3rem;
}
.table-section {
  margin-top: 4rem;
}
.consolidate__checkbox {
  text-align: center;
}
.image {
  width: 55px;
  height: 50px;
  object-fit: contain;
}
.qty {
  text-align: center;
}
.itemInfo {
  border-collapse: collapse;
  width: 100%;
}
.itemInfo td,
.itemInfo th {
  border: 1px solid #ddd;
  padding: 8px;
}
.itemInfo th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}
.input-group-text {
  color: #000000;
  font-weight: bold;
  border-radius: 0px;
}
.demo-inline-spacing {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}
.page-item.active .page-link {
  background-color: #4385f5;
}
.btn {
  border-radius: 0%;
}
.totalSelectedItems {
  color: #e87613;
  font-style: italic;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}
.prepend-value {
  display: flex;
}
.prepend {
  background: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 33px;
  color: #000000;
  font-weight: 600;
}
.value {
  border: 0.5px solid  #C9C9C9;
  width: 80px;
  height: 33px;
}

.value:focus-visible {
  outline: 0.5px solid  #C9C9C9;
}
.allSelectedText{
  color: black
}

/* Mobile screens */

@media screen and (max-width: 1200px) {
  .root {
    width: 100%;
    height: 100%;
  }
  .table-saw {
    background-color: white;
    width: 100%;
    height: 100%;
  }
  .item-card {
   padding: 0.7rem; 
   width: 100%;
   height: fit-content;
   margin-bottom: 1.5vh;
  }
  .s-card {
    min-height: 82px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .info {
    font-size: 1rem;
    margin-top: 3vh;
  }
  .c_small_checkbox {
    display: flex;
    justify-content: flex-end;
  }
  .select-all {
    margin: 2vh;
    margin-left: 0px;
    color: #e87613;
  }
  .bttn2 {
    background: #4385f5;
    color: #ffffff;
    width: fit-content;
    display: inline-block;
  }
  .totalSelectedItems {
    color: #e87613;
    font-style: italic;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1vh;
  }
  .card-value {
    display: flex;
    gap: 0.5rem;
  }
  .value {
    display: inline-block;
  }
  .image-1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image {
    width: 60px;
    height: auto;
  }
  .column-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .item-name {
    font-size-adjust: 1.2rem;
    color: #e87613;
    overflow: hidden;
  }
  .item-qty {
    font-size: 1rem;
  }
  .date-received {
    font-weight: 500;
    color: #000000;
    font-size: 0.9rem;
  }
  .middle-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25vw;
  }
  .buttons {
    margin-top: 3vh;
  }
  .lower-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root {
    width: 65em;
  }
}

</style>