<template>
    <div class="xy">
      <!-- Category Large Screen -->
      <div class="root_cat">
        <div class="cat_b" v-for="(item, indx) in productCategories.get(shippingPackageId)" :key="indx">
          <b-button class="bttn2" v-if="selectedCategoryStore.get(shippingPackageId)" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="onClick(item)" :class="{ 'active-btn': selectedCategoryStore.get(shippingPackageId).id === item.id }">
            {{ item.name }}
          </b-button>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
  import BCardCode from "@core/components/b-card-code";
  import Ripple from "vue-ripple-directive";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations, mapActions, mapState } from "vuex";
  import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BDropdown, BDropdownItem,
  } from "bootstrap-vue";
  
  export default {
    components: {
      BDropdown, BDropdownItem,
      BCardCode,
      BButton,
      BModal,
      BAlert,
      BRow,
      BImg,
      BCol,
      BLink,
      BForm,
      BCardText,
      BCardTitle,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BInputGroup,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    props: ['shippingPackageId', 'remainingWeight'],
    data() {
      return {
        selectedCategory: ''
      };
    },
    methods: {
      search(nameKey, myArray) {
  
        for (let i = 0; i < myArray.length; i++) {
          if (myArray[i].id == nameKey) {
            return myArray[i].name;
          }
        }
      },
      async onClick(category) {
        console.log("category", category)
        this.selectedCategory = category.id
        await this.setSelectedCategory({shippingPackageId: this.shippingPackageId, category: category})
      },
      ...mapActions({
        fetchProductCategories: "storeProducts/fetchProductCategoriesForShippingPackage",
        setSelectedCategory: "storeProducts/setSelectedCategoryForPackage",
        populateSelectedCategoryFromId: "storeProducts/populateSelectedCategoryFromIdForShippingPackage",
        // fetchSubCategories: "storeProducts/fetchSubCategoriesForPackage",
        setSelectedSubCategory: "storeProducts/setSelectedSubCategory",
        setCurrentPage: "storeProducts/setCurrentPage",
        fetchProducts: "storeProducts/fetchProductsForShippingPackage",
        getItems: "storeShoppingCartItems/getItemsInShippingPackage",
        getVariants: "storeProducts/getVariantsForShippingPackage"
      }),
    },
    computed: mapState({
      productCategories(state) {
        // if (state.storeProducts.productCategoriesForShippingPackage) {
          return state.storeProducts.productCategoriesForShippingPackage;
        // }
        // return this.list;
      },
      selectedCategoryStore(state) {
        return state.storeProducts.selectedCategoryForShippingPackage;
      },
      subCategories(state) {
        return state.storeProducts.subCategories;
      },
      selectedSubCategory(state) {
        return state.storeProducts.selectedSubCategory;
      },
      selectedSubCategoryName(state) {
        let x = this.selectedSubCategory;
        let y = state.storeProducts.subCategories;
        // console.log('x y  : ', x, y);
        return (state.storeProducts.selectedSubCategory && y)
          ? this.search(x, y)
          : 'Select subcategory..';
      },
      currentPage(state) {
        return state.storeProducts.currentPage;
      },
      activeTab(state){
        return state.storeShoppingCartItems.activeTab
      }
    }),
  
    async mounted() {
      await this.fetchProductCategories({shippingPackageId: this.shippingPackageId});
      this.selectedCategory = this.productCategories.get(this.shippingPackageId)[0].id
      await this.setSelectedCategory({shippingPackageId: this.shippingPackageId, category: this.productCategories.get(this.shippingPackageId)[0]})
      await this.getVariants({shippingPackageId: this.shippingPackageId, weight: 0})
    },
    watch:{
      async selectedCategoryStore(nVal, oVal){
        if(nVal!=oVal){
          console.log("watch called");
          // await this.fetchProducts({shippingPackageId: this.shippingPackageId})
          if(this.activeTab == 0){
            await this.getVariants({shippingPackageId: this.shippingPackageId, weight: 0})
          }
          else{
            await this.getVariants({shippingPackageId: this.shippingPackageId, weight: this.remainingWeight})
          }
          // await this.getItems({shippingPackageId: this.shippingPackageId, getQuantity: false})
        }
      },
    }
  };
  </script>
  <style scoped>
  .root_cat {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
  }
  .root_cat>* {
    padding: 0.2rem;
  }

 /* styling for chrome, safari */
/* Set the width and height of the scroll thumb */
div::-webkit-scrollbar {
  width: 12px; /* Width of the vertical scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

/* Customize the colors of the scroll thumb and track */
div::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

div::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

div::-webkit-scrollbar-track {
  background: #eee; /* Color of the scrollbar track */
  border-radius: 6px; /* Rounded corners for the track */
}

/* Style for Firefox */
/* This example customizes the scrollbars for both horizontal and vertical scrollbars */

/* Set the width and height of the scroll thumb */
*{
  scrollbar-width: thin; /* Width of the scrollbar (thin or auto) */
  scrollbar-color: #888 #eee; /* Color of the scroll thumb and track */
}

/* Customize the colors of the scroll thumb and track */
div::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

div::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

div::-webkit-scrollbar-track {
  background: #eee; /* Color of the scrollbar track */
  border-radius: 6px; /* Rounded corners for the track */
}

  
  .cat_b {
    font-size: 1rem;
    color: #414141;
  }
  
  .bttn2 {
    min-width: 4rem;
    max-height: 40px;
    border: 1px solid #CFE8FF;
    background: transparent;
    color: #4385f5;
    border-radius: 2rem;
  }
  
  .active-btn {
    background: #CFE8FF;
  }
  
  .root_cat_sm {
    display: none;
  }
  
  
  
  /* .root_subc { */
    /* margin-left: 8rem; */
    
  
  /* } */
  
  .drop_down__ {
    min-width: 200px;
    /* margin-left: auto; */
    /* margin-left: auto; */
  }
  
  .subcategory__{
    margin-left: 8rem;
  
  }
  
  
  
  
  /*========================= Media Queries (Small devices) =========================*/
  @media screen and (max-width: 600px) {
  
    .subcategory__{
      margin-left: auto;
      width: max-content;
      margin-right:0.3rem;
    }
    
    .root_cat_sm {
      display: flex;
      flex-wrap: wrap;
      background: #4385f5;
      color: #fff;
      align-items: center;
      /* justify-content: center; */
      height: 3rem;
      height: max(max-content, 3rem);
      font-weight: 500;
      font-size: 1rem;
      margin: 0.3rem;
      padding: 0 0.7rem;
    }
  
    .title_cat {
      margin-right: 0.5rem;
    }
  
    select {
      background: transparent;
      outline: none;
      border: none;
      color: #fff;
    }
    div::-webkit-scrollbar {
      display: none;
    }
  }
  </style>