<template>
  <div class="root_e">
    <div class="img_box">
      <div class="imgg"></div>
    </div>
    <div class="h1">No Packages Delivered:&#40;</div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import { BCollapse, BButton, VBToggle, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BLink,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCard,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    VBToggle,
  },
  data() {
    return {};
  },
  methods: {},
  directives: {
    Ripple,
  },
  computed: {},
};
</script>
<style scoped>
.root_e {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  min-width: 921px;
  min-height: 118px;
  align-items: center;
  justify-content: center;
  /* border:2px solid black; */
}
.img_box {
  background: #f7f7f7;
  width: 224px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.imgg {
  width: 108px;
  height: 108px;
  background: url("../../../assets/yicimages/dashboard/noOrdersImage.png");
}
.h1 {
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #414141;
}
.p {
  font-weight: 400;
  font-size: 20px;
  color: #414141;
}
.orr1 {
  width: 143px;
  height: 0px;
  background: #8d8d8d;
  border: 1px solid #8d8d8d;
}
.bttn1 {
  background: #e87613;
  color: #ffffff;
  width: 185px;
  height: 40px;
}
.orr {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:1rem;
}

@media screen and (max-width: 1200px) {
  .root_e{
    display: flex;
    min-width: 0;
    max-width: none;
  }
  .img_box{
    width: 200px;
    height: 180px;
  }
  .h1{
    font-size: 20px;
  }
}
</style>
    