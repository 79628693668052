<template>
  <div class="container">
    <!-- modal -->
    <b-modal ref="abandonM2" id="modal-center" centered hide-footer hide-header>
      <div class="abandonText">
        <p class="mainTitle">Items will be abandoned !</p>
        <p class="titleContent">
          Item will be abandoned. To check the status of the return please go to
          the <b>Returns and Abandons</b> section under My Dashboard.
        </p>
        <br />
        <p class="raPath">Home > My Dashboard > Returns and Abandons</p>
        <div class="btnContainer">
          <button class="finish" @click="finish">
            <div class="finishText">Finish</div>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
export default {
  props: ["det2"],
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  data() {
    return {
      // modalShow: this.open,
      // modalShow: false,
      isChecked: false,
      list: [1, 2, 3, 4],
    };
  },

  computed: {
    ...mapState({
      addressList: (state) => state.deliveryDestination.addresses,
    })
  },
  mounted() {
    console.log("Print Params",this.det2)
  },
  created() {
    console.log("#");
  },

  methods: {
    ...mapActions({
      setAbandon: "abandons/setAbandon"
    }),
    dummy() {
      console.log("M2 called");
      this.$refs["abandonM2"].show();
    },
    async finish() {
      let d = new Date();
      d = d.toISOString();
      let index = d.indexOf('T');
      d = d.substring(0, index);
      console.log("Date : ", );
      await this.setAbandon({itemID: this.det2.itemID, reason: this.det2.reason, pc: this.det2.pc, reqDate: d});
      this.$router.push('/dashboard/myOrders/productsInWarehouse');
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
input[type="checkbox"] {
  /* border: none !important; */
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.root {
  display: inline-block;
  min-height: 470px;
  max-width: 200px !important;
  width: 200px;
  padding: 2rem;
}

.raPath {
  font-family: "Poppins";
  font-weight: 500;
}
.textArea {
  display: block;
  border: 1px solid #c9c9c9;
}

.mainTitle {
  font-family: "poppins" !important;
  font-weight: 600;
  font-size: 20px;
  color: #225aa5;
}

.titleContent {
  font-family: "poppins" !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;
  color: #414141;
}

.reason {
  font-family: "poppins";
  font-weight: 600;
  font-size: 18px;
  color: #414141;
}

/* .container {
    position: relative;
} */
.cancel {
  background-color: #d9e7fd;
  color: #4385f5 !important;
  border-radius: 0% !important;
  border: none;
  margin-top: 5%;
  margin-right: 3%;
  width: 20%;
  height: 35px;
}

.cancelText {
  font-family: "poppins";
  font-weight: 500;
  font-size: 16px;
}

.finishText {
  font-family: "poppins";
  font-weight: 500;
  font-size: 16px;
}

.finish {
  background-color: #4385f5 !important;
  color: #ffffff !important;
  border-radius: 0% !important;
  border: none;
  margin-top: 5%;
  width: 20%;
  height: 35px;
}

.btnContainer {
  text-align: center;
}

#id {
  background-color: #4385f5 !important;
  color: #ffffff !important;
}

.btn-secondary {
  background-color: #d9e7fd !important;
}
</style>