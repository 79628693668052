<template>
  <b-tabs >

      <div
        v-if="$mq === 'largeDevices'"
        v-for="(item, indx) in navItems"
        :key="indx"
      >
        <b-tab @click="clickHandler(indx)" :title="item.name" :active= "indx === parseInt(activeNavItemId)" />
      </div>
      <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
      <div v-if="this.$route.name === 'productsInWarehouse'" class="heading-1">Products In Warehouse</div>
      <div v-if="this.$route.name === 'consolidationStatus'" class="heading-1">Consolidation Status</div>
      <div v-if="this.$route.name === 'readyForShipping'" class="heading-1">Ready For Shipping</div>
      <div v-if="this.$route.name === 'packageTracking'" class="heading-1">Package Tracking</div>
      <div v-if="this.$route.name === 'packagesDelivered'" class="heading-1">Packages Delivered</div>
      </div>
  </b-tabs>
</template>

<script>
import { BNav, BNavItem, BNavbarNav, BTabs, BTab } from "bootstrap-vue";
import { mapState } from "vuex";
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  components: {
    BNav,
    BNavItem,
    BNavbarNav,
    BTabs,
    BTab
  },
  props: ["activeNavItemId"],
  data() {
    return {
      navItems: [
        { name: "Products in Warehouse" },
        { name: "Consolidation Status" },
        { name: "Ready for Shipping" },
        { name: "Package Tracking" },
        { name: "Packages Delivered"}
      ],
    };
  },
  methods: {
    clickHandler(indx) {
      this.$emit("setNavItemId", indx);
    },
  },
  mounted() {
   if(this.$route.name === 'productsInWarehouse') {
    this.clickHandler(0)
   }
   if(this.$route.name === 'consolidationStatus') {
    this.clickHandler(1)
   }
   if(this.$route.name === 'readyForShipping') {
    this.clickHandler(2)
   }
   if(this.$route.name === 'packageTracking') {
    this.clickHandler(3)
   }
   if(this.$route.name === 'packagesDelivered') {
    this.clickHandler(4)
   }
  }
};
</script>


<style>
.nav-tabs{
  font-size: 16px;
}
.nav-tabs .nav-link.active {
  color: #4385f5;
}
.nav-tabs .nav-link.active:after {
  background: linear-gradient(30deg, #4385f5, #4385f5) !important;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 1200px){
  .heading-1 {
  color: #4385f5;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 1rem;
 }
}

</style>